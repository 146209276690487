// @flow
type GeocoderAddressComponent = {
  long_name: string,
  short_name: string,
  types: Array<string>,
}

type GeocoderGeometry = {
  location: {
    lat: () => number,
    lng: () => number,
  },
  location_type: any,
  viewport: any,
}

type GeocoderResult = {
  address_components: Array<GeocoderAddressComponent>,
  formatted_address: string,
  geometry: GeocoderGeometry,
  place_id: string,
  types: Array<string>,
}

type Address = {
  street: ?string,
  number: ?string,
  state: ?string,
  city: ?string,
  neighborhood: ?string,
  postal_code: ?string,
  latitude: ?number,
  longitude: ?number,
}

export default function convertGeocoderResultToAddress(
  geocoderResult: GeocoderResult
): Address {
  const getLongnameOf = getLongNameForType.bind(null, geocoderResult)

  return {
    street: getLongnameOf('route'),
    number: getLongnameOf('street_number'),
    state: getLongnameOf('administrative_area_level_1'),
    city: getLongnameOf('administrative_area_level_3'),
    neighborhood: getLongnameOf('sublocality_level_1'),
    postal_code: getLongnameOf('postal_code'),
    latitude: geocoderResult.geometry.location.lat(),
    longitude: geocoderResult.geometry.location.lng(),
  }
}

function getLongNameForType(
  geocoderResult: GeocoderResult,
  type: string
): string | null {
  const components = geocoderResult.address_components

  const found = components.find(component => {
    return component.types.indexOf(type) !== -1
  })

  return (found && found.long_name) || null
}
