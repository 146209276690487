// @flow
import React, { Component } from 'react'

import styles from './ModalWrapper.module.scss'
import Icon from '@src/components/ui/Icon'

import type { Node as ReactNode } from 'react'

const ESCAPE_KEY = 'Escape'

type ModalWrapperProps = {
  closeModal: () => void,
  closeable?: boolean,
  children: ReactNode,
  onCancel?: () => void,
}

export default class ModalWrapper extends Component<ModalWrapperProps, *> {
  container: ?Node
  handleOverlayOnClick: (e: SyntheticEvent<*>) => void
  handleKeyDown: (e: KeyboardEvent) => void
  handleCancel: () => void

  constructor(props: ModalWrapperProps) {
    super(props)
    this.handleOverlayOnClick = this.handleOverlayOnClick.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  componentDidMount() {
    if (this.props.closeable) {
      this.addKeyDownListener()
    }
  }

  componentWillUnmount() {
    this.removeKeyDownListener()
  }

  addKeyDownListener() {
    window.document.addEventListener('keydown', this.handleKeyDown)
  }

  removeKeyDownListener() {
    window.document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown(e: KeyboardEvent) {
    if (this.props.closeable && e.key === ESCAPE_KEY) {
      this.handleCancel()
    }
  }

  handleOverlayOnClick(e: SyntheticEvent<*>) {
    const clickedInsideContainer =
      this.container && this.container.contains(e.currentTarget)

    if (!this.props.closeable || clickedInsideContainer) {
      return
    }

    this.handleCancel()
  }

  handleContainerOnClick(e: SyntheticEvent<*>) {
    e.stopPropagation()
  }

  handleCancel() {
    if (!this.props.closeable) {
      return
    }
    if (typeof this.props.onCancel === 'function') {
      new Promise((resolve: () => void, reject: () => void) => {
        this.props.closeModal()
      }).then(this.props.onCancel())
    } else {
      this.props.closeModal()
    }
  }

  render() {
    return (
      <div className={styles.overlay} onClick={this.handleOverlayOnClick}>
        <div
          className={styles.container}
          ref={container => {
            this.container = container
          }}
          onClick={this.handleContainerOnClick}
        >
          {this.props.children}

          {this.props.closeable && (
            <button className={styles.closeButton} onClick={this.handleCancel}>
              <Icon name="close" />
            </button>
          )}
        </div>
      </div>
    )
  }
}
