// @flow
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import { FieldArray } from 'redux-form'

import styles from './InviteUserToOfficeModal.module.scss'
import Input from '@src/components/ui/Input'
import Button from '@src/components/ui/Button'
import Icon from '@src/components/ui/Icon'
import FormValidationError from '@src/components/ui/FormValidationError'
import Select from '@src/components/ui/Select'

import { ROLES } from '@src/constants/userRoles'

function RoleChooser(props: { invitation: *, label: string }) {
  const { invitation, label } = props

  const options = Object.keys(ROLES).map(function(role) {
    return (
      <option value={role} key={'option' + invitation + role}>
        {ROLES[role]}
      </option>
    )
  })

  return (
    <Select
      name={`${invitation}.role`}
      key={`${invitation}.role`}
      label={label}
    >
      {options}
    </Select>
  )
}

type InviteUserToOfficeModalProps = {
  onSubmit?: () => void,
  submitting?: boolean,
  error?: string,
}

export default function InviteUserToOfficeModal(
  props: InviteUserToOfficeModalProps
) {
  const { onSubmit: handleSubmit, submitting, error } = props

  return (
    <div className={styles.container}>
      <h1 className={cx('text-title text-center', styles.title)}>
        <FormattedMessage
          id="InviteUserToOfficeModal.title"
          defaultMessage="Agregar usuario"
          description="Modal title"
        />
      </h1>

      <div className={styles.body}>
        <form className={styles.form} onSubmit={handleSubmit}>
          {error !== '' && <FormValidationError error={error} />}

          <FieldArray name="invitations" component={InvitationsFieldArray} />

          <div className={styles.buttons}>
            <Button cta onClick={handleSubmit} disabled={submitting}>
              {submitting ? (
                <FormattedMessage
                  id="InviteUserToOfficeModal.submitButtonWhileSubmitting"
                  defaultMessage="INVITANDO..."
                  description="Submit button while submitting"
                />
              ) : (
                <FormattedMessage
                  id="InviteUserToOfficeModal.submitButton"
                  defaultMessage="INVITAR"
                  description="Submit button"
                />
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

class InvitationsFieldArray extends Component<*, *> {
  componentDidMount() {
    if (this.props.fields.length === 0) {
      this.props.fields.push()
    }
  }

  componentDidUpdate() {
    if (this.props.fields.length === 0) {
      this.props.fields.push()
    }
  }

  render() {
    const { fields } = this.props

    return (
      <ul className={cx('list-unstyled', styles.emailsList)}>
        {fields.map((invitation, index) => (
          <li key={index}>
            <div className={styles.emailFieldContainer}>
              <Input name={`${invitation}.email`} label="Correo electrónico" />
            </div>

            <div className={styles.roleChooser}>
              <RoleChooser invitation={invitation} label={'Rol'} />
            </div>

            {index > 0 && (
              <Button
                className={cx('text-secondary', styles.removeEmailButton)}
                link
                type="button"
                onClick={() => fields.remove(index)}
              >
                <Icon name="unavailable" />
              </Button>
            )}
          </li>
        ))}

        <li>
          <Button
            link
            className={styles.inviteAnotherUserButton}
            type="button"
            onClick={() => fields.push()}
          >
            <FormattedMessage
              id="InviteUserToOfficeModal.inviteAnotherUserButton"
              defaultMessage="{icon} Invitar otro usuario"
              description="Submit button while submitting"
              values={{
                icon: <Icon name="plus" />,
              }}
            />
          </Button>
        </li>
      </ul>
    )
  }
}
