// @flow
import React from 'react'
import { FormattedMessage } from 'react-intl'

function UnexpectedErrorModal(error: string) {
  return {
    type: 'ImageTitleBodyButtonModal',
    closeable: true,
    title: (
      <FormattedMessage
        id="MasterListContainer.unexpectedErrorModalTitle"
        defaultMessage="¡Oh no! Hubo un error"
        description="Title"
      />
    ),
    body: <p>{error}</p>,
    buttonText: (
      <FormattedMessage
        id="MasterListContainer.unexpectedErrorModalButton"
        defaultMessage="Cerrar"
        description="Button text"
      />
    ),
  }
}

export default UnexpectedErrorModal
