// @flow
import cardValidator from 'card-validator'

export function number(value: string) {
  return cardValidator.number(value)
}

export function expirationDate(value: string) {
  return cardValidator.expirationDate(value)
}
