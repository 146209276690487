// @flow
import React from 'react'
import cx from 'classnames'
import { NavLink, Switch } from 'react-router-dom'

import styles from './NavBarSwitch.module.scss'

type NavBarSwitchProps = {
  children: *,
}

export default function NavBarSwitch(props: NavBarSwitchProps) {
  const navLinks = []

  // $FlowIgnore
  React.Children.forEach(props.children, element => {
    const isValidElement = element && element.props.name && element.props.path

    if (isValidElement) {
      navLinks.push({
        to: element.props.path,
        exact: element.props.exact,
        text: element.props.name,
      })
    }
  })

  return (
    <div>
      <ul className={cx('list-inline', styles.navBar)}>
        {navLinks.map(link => (
          <li className="list-inline-item" key={link.to}>
            <NavLink
              className={cx('text-title-3', 'text-secondary', styles.navLink)}
              activeClassName={styles['navLink--active']}
              to={link.to}
              exact={link.exact}
            >
              {link.text}
            </NavLink>
          </li>
        ))}
      </ul>

      <div className="d-flex flex-column align-items-center">
        <Switch>{props.children}</Switch>
      </div>
    </div>
  )
}
