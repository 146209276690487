// @flow
export function register() {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'CompleteRegistration',
    })
  }
}

export function registerFromInvitation() {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'CompleteInvitationRegistration',
    })
  }
}

export function orderCreated(status: string) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'OrderCreated',
      OrderStatus: status,
    })
  }
}

export function orderSuccessfulyCharged(amount: number) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'OrderCharged',
      OrderValue: amount / 100.0,
    })
  }
}

export function initialOfficeCreated() {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'InitialOfficeCreated',
    })
  }
}
