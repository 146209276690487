// @flow
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'

export type AddressDeliveryInformationInput = {
  id: string,
  first_delivery_contact_name: string,
  first_delivery_contact_phone: string,
  second_delivery_contact_name: string,
  second_delivery_contact_phone: string,
  delivery_time_window: string,
  delivery_comments: string,
}

const updateAddressDeliveryInformationMutation = graphql(
  gql`
    mutation UpdateAddressDeliveryInformation(
      $id: ID!
      $office_id: ID!
      $address: AddressDeliveryInformationInput!
    ) {
      update_address_delivery_information(
        id: $id
        office_id: $office_id
        address: $address
      ) {
        id
        first_delivery_contact_name
        first_delivery_contact_phone
        second_delivery_contact_name
        second_delivery_contact_phone
        delivery_time_window
        delivery_comments
      }
    }
  `,
  {
    name: 'updateAddressDeliveryInformationMutation',
    props: ({ updateAddressDeliveryInformationMutation }) => ({
      updateAddressDeliveryInformation: (
        id,
        office_id,
        addressDeliveryInformationInput
      ) =>
        updateAddressDeliveryInformationMutation({
          variables: {
            id,
            office_id,
            address: addressDeliveryInformationInput,
          },
        }),
    }),
  }
)

export default compose(updateAddressDeliveryInformationMutation)
