// @flow
import React, { Component } from 'react'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'

import BalanceWidget from './BalanceWidget'
import NextEventsWidget from './NextEventsWidget'

import styles from './SummaryScreen.module.scss'
import Alert from '@src/components/ui/Alert'

import type { CurrentUser } from '@src/higherOrderComponents/withCurrentUser/graphql'

type SummaryScreenProps = {
  currentUser: CurrentUser,
  currency: string,
  currentOffice: {
    id: string,
    dashboard_promotion_message: string,
  },
}

class SummaryScreen extends Component<SummaryScreenProps> {
  render() {
    const titleStyle = cx('text-title-1', styles.title)

    return (
      <div>
        <h1 className={titleStyle}>
          <FormattedMessage
            id="SummaryScreen.userGreeting"
            defaultMessage="Hola"
            description="User greeting in the summary screen"
          />{' '}
          {this.props.currentUser && this.props.currentUser.first_name}
        </h1>

        <h2 className={cx('text-headline-2', styles.summarySubtitle)}>
          <FormattedMessage
            id="SummaryScreen.summaryTitle"
            defaultMessage="Mi resumen"
            description="Catalog section title"
          />
        </h2>

        {this.renderSummary()}
      </div>
    )
  }

  renderSummary() {
    const { currency, currentOffice } = this.props

    const summaryContainerStyle = cx('row', styles.summaryContainer)
    const summaryRowStyle = cx('col-12', styles.summaryRow)

    return (
      <div className={summaryContainerStyle}>
        <div className={summaryRowStyle}>
          {currentOffice &&
            currentOffice.dashboard_promotion_message !== null && (
              <Alert type="info">
                <a href="/master-lists">
                  {currentOffice.dashboard_promotion_message}
                </a>
              </Alert>
            )}
          <div className="row">
            <BalanceWidget
              currency={currency}
              currentOfficeId={currentOffice.id}
            />
            <NextEventsWidget currentOfficeId={currentOffice.id} />
          </div>
        </div>
      </div>
    )
  }
}

export default SummaryScreen
