// @flow
import React, { Component } from 'react'
import compose from 'lodash/flowRight'

import * as errorReporter from '@src/lib/errorReporter'

import DeliveryInformationModal from './DeliveryInformationModal'
import graphql from './graphql'

type FormData = {
  id: string,
  first_delivery_contact_name: string,
  first_delivery_contact_phone: string,
  second_delivery_contact_name: string,
  second_delivery_contact_phone: string,
  delivery_time_window: string,
  delivery_comments: string,
}

type Address = {
  id: string,
  first_delivery_contact_name: string,
  first_delivery_contact_phone: string,
  second_delivery_contact_name: string,
  second_delivery_contact_phone: string,
  delivery_time_window: string,
  delivery_comments: string,
}

type DeliveryInformationModalContainerProps = {
  closeModal: () => void,
  address: Address,
  currentUserName: string,
  currentUserPhone: string,
  officePhone: string,
  officeId: string,
  updateAddressDeliveryInformation: (
    id: string,
    office_id: string,
    address: Address
  ) => Promise<{
    data: {
      update_address_delivery_information: Address,
    },
  }>,

  // Redux form props
  handleSubmit: Function,
}

export class DeliveryInformationModalContainer extends Component<
  DeliveryInformationModalContainerProps,
  *
> {
  handleSubmit: (formData: FormData) => Promise<void>

  constructor(props: DeliveryInformationModalContainerProps) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = { error: '' }
  }

  async handleSubmit(formData: FormData) {
    const { updateAddressDeliveryInformation, address, officeId } = this.props

    return updateAddressDeliveryInformation(address.id, officeId, formData)
      .then(result => {
        this.props.closeModal()
      })
      .catch(error => {
        errorReporter.log(error, formData)
      })
  }

  render() {
    const {
      address,
      currentUserName,
      officePhone,
      currentUserPhone,
    } = this.props

    return (
      <DeliveryInformationModal
        onSubmit={this.handleSubmit}
        onSubmitSuccess={() => {}}
        initialValues={{
          first_delivery_contact_name:
            address.first_delivery_contact_name || currentUserName,
          first_delivery_contact_phone:
            address.first_delivery_contact_phone ||
            officePhone ||
            currentUserPhone,
          second_delivery_contact_name: address.second_delivery_contact_name,
          second_delivery_contact_phone: address.second_delivery_contact_phone,
          delivery_time_window: address.delivery_time_window,
          delivery_comments: address.delivery_comments,
        }}
      />
    )
  }
}

export default compose(graphql)(DeliveryInformationModalContainer)
