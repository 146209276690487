import { defineMessages } from 'react-intl'

const messages = defineMessages({
  nameLabel: {
    id: 'DeliveryInformationModal.nameLabel',
    defaultMessage: 'Nombre',
    description: 'Label for the address delivery contact Name field',
  },
  phoneLabel: {
    id: 'DeliveryInformationModal.phoneLabel',
    defaultMessage: 'Telefono/s',
    description: 'Label for the address delivery contact phone field',
  },
  deliveryTimeWindow: {
    id: 'DeliveryInformationModal.deliveryTimeWindowStart',
    defaultMessage: 'Entregar en horario:',
    description: 'Label for the address delivery time window stop field',
  },
  deliveryComments: {
    id: 'DeliveryInformationModal.deliveryWarning',
    defaultMessage: 'Comentarios, advertencias al entregar:',
    description: 'Label for the address delivery time window stop field',
  },
})

export default messages
