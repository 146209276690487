// @flow
import React, { Component } from 'react'
import { SubmissionError } from 'redux-form'
import compose from 'lodash/flowRight'
import moment from 'moment-timezone'
import _ from 'lodash'

import UpdateMasterListModal from './UpdateMasterListModal'
import graphql from './graphql'
import * as errorReporter from '@src/lib/errorReporter'

import type { MasterListInput } from './types'

type UpdateMasterListModalProps = {
  masterList: Object,

  // Provided by the modal
  closeModal: () => void,

  // Provided by graphql
  updateMasterList: (
    masterListId: string,
    masterListInput: MasterListInput
  ) => Promise<void>,
}

type UpdateMasterListModalContainerProps = {
  initialStartingDate: string,
  closeModal: () => void,
  updateMasterList: (
    masterListId: string,
    masterListInput: MasterListInput
  ) => Promise<void>,
  masterList: Object,
}

export class UpdateMasterListModalContainer extends Component<
  UpdateMasterListModalProps,
  *
> {
  constructor(props: UpdateMasterListModalContainerProps) {
    super(props)

    var startingDate
    const periodicity = this.props.masterList.periodicity

    if (this.props.masterList.starting_date === null) {
      startingDate = moment(
        this.props.masterList.next_delivery_dates_available[0]
      )
    } else {
      startingDate = this.props.masterList.starting_date
    }

    this.isShippingDate = this.isShippingDate.bind(this)

    this.state = {
      startingDate: startingDate,
      periodicity: periodicity,
    }
  }

  async handleSubmit(masterListInput: MasterListInput) {
    const data = {
      name: masterListInput.name,
      periodicity: masterListInput.periodicity,
      starting_date: this.state.startingDate,
    }

    try {
      await this.props.updateMasterList(this.props.masterList.id, data)
    } catch (e) {
      errorReporter.log(e)
      throw new SubmissionError({ _error: e.message })
    }
  }

  handleSubmitSuccess() {
    this.props.closeModal()
  }

  handleStartDeliveryOnDateChange(startingDate: string) {
    if (startingDate !== null) {
      this.setState(() => ({
        startingDate: moment(startingDate).toISOString(),
      }))
    }
  }

  handlePeriodicityChange(periodicity: string) {
    if (periodicity !== null) {
      this.setState(() => ({
        periodicity: periodicity,
      }))
    }
  }

  isShippingDate = (date: moment) => {
    const deliveryDays = this.props.masterList.next_delivery_dates_available.map(
      date => {
        return moment(date).format('L')
      }
    )
    const day = date.format('L')

    return !_.includes(deliveryDays, day)
  }

  render() {
    return (
      <UpdateMasterListModal
        initialValues={{
          name: this.props.masterList.name,
          periodicity: this.state.periodicity,
          startingDate: this.state.startingDate,
        }}
        onSubmit={formData => this.handleSubmit(formData)}
        onSubmitSuccess={() => this.handleSubmitSuccess()}
        onChangeStartingDeliveryDate={newDate =>
          this.handleStartDeliveryOnDateChange(newDate)
        }
        onPeriodicityChange={periodicity =>
          this.handlePeriodicityChange(periodicity)
        }
        isShippingDate={day => this.isShippingDate(day)}
      />
    )
  }
}

export default compose(graphql)(UpdateMasterListModalContainer)
