// @flow
import React from 'react'
import gql from 'graphql-tag'
import cx from 'classnames'

import styles from './ChargeOrderModal.module.scss'
import Icon from '@src/components/ui/Icon'
import CreditCardIcon from '@src/components/ui/CreditCardIcon'

import type { PaymentMethod } from './types'

type CreditCardPaymentMethodProps = {
  onSelect: (paymentMethod: PaymentMethod) => void,
  selected: boolean,
  creditCard: {
    id: string,
    brand: string,
    name: string,
    last4: string,
  },
}

export const CREDIT_CARD = 'CREDIT_CARD'

export default function CreditCardPaymentMethod(
  props: CreditCardPaymentMethodProps
) {
  const { creditCard, onSelect, selected } = props

  return (
    <div
      className={cx(styles.option, {
        [styles.optionSelected]: selected,
      })}
    >
      <div
        className={styles.optionHeader}
        onClick={() =>
          onSelect({
            type: CREDIT_CARD,
            data: {
              id: creditCard.id,
            },
          })
        }
      >
        <Icon name="check" className={styles.selectedIcon} />

        <div className={styles.existingCard}>
          <CreditCardIcon
            className={styles.creditCardIcon}
            name={creditCard.brand}
          />

          <div>
            <div className="text-small-body-2">{creditCard.name}</div>

            <div className="text-small-body">
              •••• •••• •••• {creditCard.last4}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CreditCardPaymentMethod.fragments = {
  creditCard: gql`
    fragment CreditCardPaymentMethod on CreditCard {
      id
      brand
      name
      last4
    }
  `,
}
