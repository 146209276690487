// @flow
import Loadable from 'react-loadable'

import Loader from '@src/components/ui/ReactLoadableLoader'

const MasterListLoader = Loadable({
  loader: () => import('./MasterListContainer'),
  loading: Loader,
})

export default MasterListLoader
