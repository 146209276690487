// @flow
import React, { Component } from 'react'
import compose from 'lodash/flowRight'
import { reduxForm, SubmissionError } from 'redux-form'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import ChargeOrderModal from './ChargeOrderModal'
import graphql from './graphql'
import redux from './redux'
import { CREDIT_CARD } from './CreditCardPaymentMethod'
import { NEW_CREDIT_CARD } from './NewCreditCardPaymentMethod'
import * as errorReporter from '@src/lib/errorReporter'
import PaymentTokensGenerator from '@src/lib/PaymentTokensGenerator'
import { separator as expirationDateSeparator } from '@src/components/ui/CreditCardExpirationDateInput/normalizeExpirationDate'

import type { PaymentMethod, Office } from './types'
import type { CreditCard } from '@src/lib/PaymentTokensGenerator/types'
import * as events from '@src/lib/events'

type CardFormData = {
  name: string,
  number: string,
  expirationDate: string,
  cvc: string,
}

type FormData = {
  amount: number,
  creditCard?: CardFormData,
}

type ChargeOrderModalContainerProps = {
  targetPrice: number,
  officeId: string,
  orderId: string,
  onSuccessfulCharge: () => void,
  currency: string,
  intl: *,

  // Provided by redux
  isCurrentRegionInMexico: boolean,

  // Provided by graphql
  data: {
    loading: boolean,
    credit_cards?: Array<{
      id: string,
      brand: string,
      name: string,
      last4: string,
    }>,
    office?: Office,
  },
  createCreditCard: (
    officeId: string,
    conektaToken?: string,
    stripeToken?: string
  ) => Promise<{
    data: {
      create_credit_card: {
        id: string,
      },
    },
  }>,
  chargeOrder: (
    orderId: string,
    creditCardId: string
  ) => Promise<{
    data: {
      charge_order: {
        total_price: number,
        id: string,
      },
    },
  }>,

  // Provided by ModalRoot
  closeModal: () => void,

  // Provided by redux-form
  handleSubmit: Function,
  submitting: boolean,
  error?: string,
  history: *,
}

export class ChargeOrderModalContainer extends Component<
  ChargeOrderModalContainerProps,
  *
> {
  handleSelectPaymentMethod: Function
  handleClickPayButton: Function

  constructor(props: ChargeOrderModalContainerProps) {
    super(props)

    this.state = {
      selectedPaymentMethod: undefined,
    }

    this.handleSelectPaymentMethod = this.handleSelectPaymentMethod.bind(this)
    this.handleClickPayButton = this.handleClickPayButton.bind(this)
  }

  handleSelectPaymentMethod(paymentMethod: PaymentMethod) {
    this.setState(() => ({ selectedPaymentMethod: paymentMethod }))
  }

  handleClickPayButton(formData: FormData) {
    const paymentMethod = this.state.selectedPaymentMethod

    if (!paymentMethod) {
      throw new SubmissionError({
        _error: 'Debes elegir un método de pago.',
      })
    }

    switch (paymentMethod.type) {
      case CREDIT_CARD:
        return this.handleCreditCardPaymentMethod(formData)

      case NEW_CREDIT_CARD:
        if (formData.creditCard) {
          return this.handleNewCreditCardPaymentMethod(
            formData.creditCard,
            formData.amount
          )
        }
        break

      default:
        return null
    }
  }

  logOrderCharged(amount: number) {
    events.orderSuccessfulyCharged(amount)
  }

  async handleCreditCardPaymentMethod(formData: FormData) {
    const paymentMethod = this.state.selectedPaymentMethod
    if (!paymentMethod) {
      return this.throwMissingPaymentMethodError()
    }

    if (!paymentMethod.data || !paymentMethod.data.id) {
      return this.throwMissingCreditCardIdError()
    }

    await this.chargeOrder(this.props.orderId, paymentMethod.data.id)

    this.closeModal().then(this.props.onSuccessfulCharge())
  }

  async handleNewCreditCardPaymentMethod(
    cardFormData: CardFormData,
    amount: number
  ) {
    const creditCardDetails = this.getCreditCardFromFormData(cardFormData)
    const paymentTokens = await this.generatePaymentTokens(creditCardDetails)

    const creditCard = await this.createCreditCard(
      this.props.officeId,
      paymentTokens.conekta,
      paymentTokens.stripe
    )

    await this.chargeOrder(this.props.orderId, creditCard.id)

    this.closeModal().then(this.props.onSuccessfulCharge())
  }

  getCreditCardFromFormData(cardFormData: CardFormData): CreditCard {
    const getMonth = (expirationDate: string) =>
      expirationDate.split(expirationDateSeparator, 2)[0]
    const getYear = (expirationDate: string) =>
      expirationDate.split(expirationDateSeparator, 2)[1]

    const creditCard = cardFormData || {}

    return {
      name: creditCard.name,
      number: creditCard.number,
      cvc: creditCard.cvc,
      exp_month: getMonth(creditCard.expirationDate),
      exp_year: getYear(creditCard.expirationDate),
    }
  }

  async generatePaymentTokens(card: CreditCard) {
    const tokensGenerator = new PaymentTokensGenerator(card)
    const paymentTokens = await tokensGenerator.generateTokens()

    const conekta = paymentTokens.conekta.token
    const stripe = paymentTokens.stripe.token

    return {
      conekta,
      stripe,
    }
  }

  async createCreditCard(
    officeId: string,
    conektaToken?: string,
    stripeToken?: string
  ) {
    try {
      const createCreditCardResponse = await this.props.createCreditCard(
        officeId,
        conektaToken,
        stripeToken
      )
      return createCreditCardResponse.data.create_credit_card
    } catch (e) {
      errorReporter.log(e)
      return this.throwGenericError()
    }
  }

  async chargeOrder(orderId: string, creditCardId: string) {
    try {
      const chargeOrderResponse = await this.props.chargeOrder(
        orderId,
        creditCardId
      )
      const order = chargeOrderResponse.data.charge_order
      this.logOrderCharged(order.total_price)

      return order
    } catch (e) {
      errorReporter.log(e)
      return this.throwGenericError()
    }
  }

  closeModal(): Promise<void> {
    return new Promise((resolve, reject) => {
      resolve(this.props.closeModal())
    })
  }

  goToOrders() {
    this.props.history.push(`/orders`)
  }

  throwMissingPaymentMethodError() {
    errorReporter.log(new Error('Missing payment method.'))

    this.throwGenericError()
  }

  throwMissingCreditCardIdError() {
    errorReporter.log(
      new Error(
        'Missing payment method data or id while attempting a CreditCardPaymentMethod.'
      )
    )

    this.throwGenericError()
  }

  throwGenericError() {
    throw new SubmissionError({
      _error: 'Hubo un error procesando el cobro. Por favor intente más tarde.',
    })
  }

  isLoading() {
    return [this.props.data.loading, !this.props.data.credit_cards].some(
      x => x === true
    )
  }

  shouldDisplayCreditCards() {
    return this.props.isCurrentRegionInMexico
  }

  render() {
    const {
      data: { credit_cards: creditCards, office },
      handleSubmit,
      submitting,
      error,
      targetPrice,
      currency,
      intl,
      isCurrentRegionInMexico,
      orderId,
    } = this.props

    if (this.isLoading() || !creditCards || !office) {
      return null
    }

    const formattedTargetPrice = String(
      intl.formatNumber(targetPrice / 100, {
        style: 'currency',
        currency: currency,
      })
    )

    return (
      <ChargeOrderModal
        onSelectPaymentMethod={this.handleSelectPaymentMethod}
        selectedPaymentMethod={this.state.selectedPaymentMethod}
        onClickPayButton={handleSubmit(this.handleClickPayButton)}
        paying={submitting}
        error={error}
        creditCards={creditCards}
        displayCreditCards={this.shouldDisplayCreditCards()}
        targetPrice={formattedTargetPrice}
        office={office}
        orderId={orderId}
        isCurrentRegionInMexico={isCurrentRegionInMexico}
      />
    )
  }
}

export default compose(
  redux,
  graphql,
  injectIntl,
  reduxForm({
    form: 'paymentMethodModal',
  }),
  withRouter
)(ChargeOrderModalContainer)
