// @flow
import * as creditCard from '@src/utils/creditCard'

export function validExpirationDate(value: string): ?string {
  const { isPotentiallyValid: valid } = creditCard.valid.expirationDate(value)

  if (!valid) {
    return 'La fecha no es válida'
  }
}
