// @flow
import React from 'react'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'

import styles from './ConfirmActionModal.module.scss'
import Button from '@src/components/ui/Button'
import FormValidationError from '@src/components/ui/FormValidationError'

import type { Node as ReactNode } from 'react'

type ConfirmActionModalProps = {
  title: ReactNode,
  description: ReactNode,
  error?: string,
  onCancel: () => void,
  onConfirm: () => Promise<void>,
  confirming: boolean,
}

export default function ConfirmActionModal(props: ConfirmActionModalProps) {
  return (
    <div className={styles.container}>
      <h1 className={cx('text-title text-center', styles.title)}>
        {props.title}
      </h1>

      <div className={cx('text-dark', styles.description)}>
        <div className="row">
          <div className="col-12">{props.description}</div>
        </div>

        {props.error && (
          <div className={cx('row', styles.error)}>
            <div className="col-12">
              <FormValidationError error={props.error} />
            </div>
          </div>
        )}
      </div>

      <div className={styles.buttons}>
        <Button
          link
          className={styles.cancelButton}
          onClick={props.onCancel}
          disabled={props.confirming}
        >
          <FormattedMessage
            id="ConfirmActionModal.cancelButton"
            defaultMessage="CANCELAR"
            description="Cancel action button"
          />
        </Button>

        <Button
          cta
          className={styles.confirmButton}
          onClick={props.onConfirm}
          disabled={props.confirming}
        >
          {props.confirming ? (
            <FormattedMessage
              id="ConfirmActionModal.confirmButtonWhileConfirming"
              defaultMessage="CONFIRMANDO..."
              description="Confirm action button while confirming"
            />
          ) : (
            <FormattedMessage
              id="ConfirmActionModal.confirmButton"
              defaultMessage="CONFIRMAR"
              description="Confirm action button"
            />
          )}
        </Button>
      </div>
    </div>
  )
}
