// @flow
import Loadable from 'react-loadable'

import Loader from '@src/components/ui/Loader'

const OfficeDetailContainer = Loadable({
  loader: () => import('./OfficeDetailContainer'),
  loading: Loader,
})

export default OfficeDetailContainer
