// @flow
/* eslint-disable react/style-prop-object */
import React from 'react'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import Card from '@src/components/ui/Card'
import WidgetLoader from '@src/components/ui/WidgetLoader'

import styles from '../SummaryScreen.module.scss'

import type { Office, Orders } from './graphql'

export type BalanceWidgetProps = {
  office?: Office,
  orders?: Orders,
  currency: string,
  loading: boolean,
}

const renderEmpty = () => {
  return (
    <p className="text-body text-dark">
      <FormattedMessage
        id="BalanceWidget.emptyMessage"
        defaultMessage="No tienes actividad, recarga tu saldo para empezar a agendar servicios."
        description="Balance empty message"
      />
    </p>
  )
}

const renderWithData = (orders = [], currency) => {
  const lastOrder = orders.filter(order => order.type !== 'SERVICE_ORDER')[0]

  if (!lastOrder) {
    return null
  }

  if (!lastOrder) {
    return renderEmpty()
  }

  return (
    <div>
      <p className="text-body text-dark mb-0">
        <FormattedMessage
          id="BalanceWidget.lastOrderNumber"
          defaultMessage="Órden #{orderNumber}"
          description="Balance last order number"
          values={{
            orderNumber: lastOrder.id,
          }}
        />{' '}
        <NavLink
          to={`/orders/${lastOrder.id}`}
          className="text-body-2 link-blue"
        >
          <FormattedMessage
            id="BalanceWidget.lastOrderDetailsLink"
            defaultMessage="ver detalle"
            description="Balance last order details link"
          />
        </NavLink>
      </p>
    </div>
  )
}

const renderBalanceWidget = (office = {}, orders = [], currency) => {
  return (
    <Card className={styles.summaryCard}>
      <h3 className="text-small text-gray text-uppercase">
        <FormattedMessage
          id="BalanceWidget.title"
          defaultMessage="Órdenes"
          description="Balance title"
        />
      </h3>
      <div className={styles.summaryCardBody}>
        <p className="text-body-2 text-dark mb-2">
          <FormattedMessage
            id="BalanceWidget.lastOrderTitle"
            defaultMessage="Última actividad:"
            description="Balance last order title"
          />
        </p>
        {orders.length > 0 ? renderWithData(orders, currency) : renderEmpty()}
      </div>
    </Card>
  )
}

export default function BalanceWidgetScreen(props: BalanceWidgetProps) {
  const { office, orders, currency, loading } = props

  return (
    <div className="col-6">
      {loading ? (
        <WidgetLoader />
      ) : (
        renderBalanceWidget(office, orders, currency)
      )}
    </div>
  )
}
