// @flow
import React, { Component } from 'react'
import cx from 'classnames'

type IconProps = {
  name: string,
  className?: string,
}

export default class Icon extends Component<IconProps, *> {
  render() {
    const className = cx(`icon-${this.props.name}`, this.props.className)

    return <span className={className} />
  }
}
