// @flow
import React from 'react'

type InputProps = {
  type?: string,
}

export default function Input(props: InputProps): * {
  return <input {...props} type={props.type || 'text'} />
}
