// @flow
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import AuthRoute from '@src/components/react-router/AuthRoute'
import Redirect from '@src/components/react-router/Redirect'
import ModalRoot from '@src/components/modals/ModalRoot'
import AlertRoot from '@src/components/ui/Alert/AlertRoot'
import Header from '@src/components/ui/Header'
import Footer from '@src/components/ui/Footer'
import LoginLoader from '@src/components/login/LoginLoader'
import RegisterLoader from '@src/components/register/RegisterLoader'
import ForgotPasswordLoader from '@src/components/forgot-password/ForgotPasswordLoader'
import ResetPasswordLoader from '@src/components/reset-password/ResetPasswordLoader'
import RegisterWithInviteLoader from '@src/components/register-with-invite/RegisterWithInviteLoader'
import DashboardContainer from '@src/components/dashboard/DashboardContainer'
import Error404 from '@src/components/404/Error404Loader'
import Error500 from '@src/components/500/Error500Loader'

export default function UIRoot(initialData: *) {
  const DashboardRoute = () => <DashboardContainer {...initialData} />

  return (
    <div>
      <ModalRoot />

      <Header {...initialData} />

      <AlertRoot />

      <Switch>
        {/* eslint-disable react/jsx-max-props-per-line */}
        <Redirect exact from="/dashboard" to="/" />
        <Redirect exact from="/dashboard/:url" to="/:url" />
        <Redirect from="/product-order/:id" to="/orders/:id" />
        <Redirect exact from="/autopilot" to="/master-lists" />
        <Redirect exact from="/autopilot/products" to="/master-lists" />

        <Route path="/login" component={LoginLoader} />
        <Route path="/register" component={RegisterLoader} />
        <Route path="/invites/:inviteId" component={RegisterWithInviteLoader} />

        <Route path="/password/new" component={ForgotPasswordLoader} />
        <Route path="/password/edit" component={ResetPasswordLoader} />

        <AuthRoute path="/" component={DashboardRoute} />

        <Redirect
          from="/orders/:orderId/quotes/:quoteId"
          to="/orders/:orderId"
        />

        <Route path="/500" component={Error500} />
        <Route component={Error404} />
        {/* eslint-enable react/jsx-max-props-per-line */}
      </Switch>

      <Footer />
    </div>
  )
}
