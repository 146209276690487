// @flow
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import Image from '@src/components/ui/Image'

import image from './images/cart.png'

import styles from './AutopilotWelcomeModal.module.scss'

type AutopilotWelcomeModalProps = {
  closeModal: () => void,
}

export default function AutopilotWelcomeModal(
  props: AutopilotWelcomeModalProps
) {
  return (
    <div className={styles.container}>
      <Image className="mb-4" src={image} />

      <h4 className="mb-3 ml-4 mr-4">
        <FormattedMessage
          id="AutopilotWelcomeModal.title"
          defaultMessage="¡Tener todo lo que necesitas jamás ha sido tan fácil!"
          description={`The autopilot welcome modal modal title`}
        />
      </h4>

      <p className="text-dark mb-4">
        <FormattedMessage
          id="AutopilotWelcomeModal.description"
          defaultMessage="En menos de 8 horas te haremos llegar la lista con la cotización de todos estos productos para que lo apruebes y procedamos con el envío. En Tandem, jamás te cobraremos sin tu autorización."
          description={`The autopilot welcome modal description`}
        />
      </p>

      <Link
        className="btn btn-default"
        to="/autopilot"
        onClick={props.closeModal}
      >
        <FormattedMessage
          id="AutopilotWelcomeModal.goToMasterListButton"
          defaultMessage="Ver mi cotizador automático"
          description={`Go to my master list`}
        />
      </Link>
    </div>
  )
}
