// @flow
import React, { Component } from 'react'

import ImageTitleBodyButtonModal from './ImageTitleBodyButtonModal'

type ImageTitleBodyButtonModalContainerProps = {
  image: *,
  title: *,
  body: *,
  buttonText: *,
  onClick: () => Promise<void>,
  onClickSuccess?: () => void,
  onClickFailure?: (e: Error) => void,

  // Provided by modal
  closeModal: () => void,
}

export default class ImageTitleBodyButtonModalContainer extends Component<
  ImageTitleBodyButtonModalContainerProps,
  *
> {
  state: {
    buttonDisabled: boolean,
    error?: string,
  }

  constructor(props: ImageTitleBodyButtonModalContainerProps) {
    super(props)

    this.state = {
      buttonDisabled: false,
    }
  }

  async handleClick() {
    this.setState(() => ({ error: undefined, buttonDisabled: true }))

    try {
      if (this.props.onClick) {
        await this.props.onClick()
      }

      this.handleClickSuccess()
    } catch (e) {
      this.handleClickFailure(e)
    }

    this.setState(() => ({ buttonDisabled: false }))
  }

  handleClickSuccess() {
    if (this.props.onClickSuccess) {
      this.props.onClickSuccess()
    }

    this.props.closeModal()
  }

  handleClickFailure(e: Error) {
    this.setState(() => ({ error: e.message }))

    if (this.props.onClickFailure) {
      this.props.onClickFailure(e)
    }
  }

  render() {
    const { image, title, body, buttonText } = this.props
    const { buttonDisabled, error } = this.state

    return (
      <ImageTitleBodyButtonModal
        image={image}
        title={title}
        body={body}
        buttonText={buttonText}
        buttonDisabled={buttonDisabled}
        onClick={() => {
          this.handleClick()
        }}
        error={error}
      />
    )
  }
}
