// @flow
import { defineMessages } from 'react-intl'

export default defineMessages({
  periodicityLabel: {
    id: 'UpdateMasterListModal.periodicityLabel',
    defaultMessage: 'Quiero recibir esta lista',
    description: 'Label for the form field asking the periodicity of the list',
  },
  periodicityIRREGULAR: {
    id: 'UpdateMasterListModal.periodicityIRREGULAR',
    defaultMessage: 'Solo cuando haga la orden manualmente',
    description: 'IRREGULAR periodicity option',
  },
  periodicityOPENING: {
    id: 'UpdateMasterListModal.periodicityOPENING',
    defaultMessage: 'Orden única de apertura',
    description: 'OPENING periodicity option',
  },
  periodicityWEEKLY: {
    id: 'UpdateMasterListModal.periodicityWEEKLY',
    defaultMessage: 'Semanalmente',
    description: 'WEEKLY periodicity option',
  },
  periodicityBIWEEKLY: {
    id: 'UpdateMasterListModal.periodicityBIWEEKLY',
    defaultMessage: 'Quincenalmente',
    description: 'BIWEEKLY periodicity option',
  },
  periodicityMONTHLY: {
    id: 'UpdateMasterListModal.periodicityMONTHLY',
    defaultMessage: 'Mensualmente',
    description: 'MONTHLY periodicity option',
  },
})
