// @flow
import React from 'react'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'

import CopyableInput from '@src/components/ui/CopyableInput'
import Icon from '@src/components/ui/Icon'

import styles from './ChargeOrderModal.module.scss'

import type { PaymentMethod, Office } from './types'

type BankTransferPaymentMethodProps = {
  onSelect: (paymentMethod: PaymentMethod) => void,
  selected: boolean,
  office: Office,
  orderId: string,
  isCurrentRegionInMexico: boolean,
}

export const BANK_TRANSFER = 'BANK_TRANSFER'

function BankTransferPaymentMethod(props: BankTransferPaymentMethodProps) {
  const { onSelect, selected, office, orderId, isCurrentRegionInMexico } = props

  if (!office.bank_account) {
    return null
  }

  const accountClass = isCurrentRegionInMexico
    ? 'text-headline'
    : 'text-small-body-2'
  const accountTitleColor = isCurrentRegionInMexico ? 'text-black' : 'text-gray'
  const accountColor = isCurrentRegionInMexico ? 'link-blue' : 'text-black'
  const smallColumn = isCurrentRegionInMexico ? 'col-3' : 'col-5'
  const bigColumn = isCurrentRegionInMexico ? 'col-9' : 'col-7'

  const { bank_account: bankAccount } = office

  return (
    <div
      className={cx(styles.option, {
        [styles.optionSelected]: selected,
      })}
    >
      <div
        className={styles.optionHeader}
        onClick={() =>
          onSelect({
            type: BANK_TRANSFER,
          })
        }
      >
        <Icon name="check" className={styles.selectedIcon} />

        <div className="text-small-body-2">
          <FormattedMessage
            id="ChargeOrderModal.interbankTransferTitle"
            defaultMessage="Transferencia vía SPEI"
            description="Interbank transfer title"
          />
        </div>
      </div>

      {selected && (
        <div className={styles.optionBody}>
          <div className="row">
            <div className={cx(smallColumn, accountClass, accountTitleColor)}>
              <FormattedMessage
                id="BankTransferMethod.interbankAccountNumber"
                defaultMessage="CLABE:"
                description="Interbank account title"
              />
            </div>
            <div className={bigColumn}>
              <CopyableInput
                value={bankAccount.interbank_account_number}
                className={cx(accountClass, accountColor)}
              />
            </div>
          </div>
          <div className="row pt-2">
            <div className={cx(smallColumn, 'text-small-body-2 text-gray')}>
              <FormattedMessage
                id="BankTransferMethod.accountName"
                defaultMessage="Beneficiario:"
                description="Interbank account recipient"
              />
            </div>
            <div className={cx(bigColumn, 'text-small-body-2 text-black')}>
              <FormattedMessage
                id="BankTransferMethod.accountNameRecipient"
                defaultMessage={bankAccount.recipient}
                description="Interbank account recipient name"
              />
            </div>
          </div>
          <div className="row pt-2">
            <div className={cx(smallColumn, 'text-small-body-2 text-gray')}>
              <FormattedMessage
                id="BankTransferMethod.bank"
                defaultMessage="Banco:"
                description="Interbank account bank"
              />
            </div>
            <div className={cx(bigColumn, 'text-small-body-2 text-black')}>
              <FormattedMessage
                id="BankTransferMethod.bankName"
                defaultMessage={bankAccount.bank}
                description="Interbank account bank name"
              />
            </div>
          </div>
          {isCurrentRegionInMexico && (
            <div className="row pt-2">
              <div className={cx(smallColumn, 'text-small-body-2 text-gray')}>
                <FormattedMessage
                  id="BankTransferMethod.reference"
                  defaultMessage="Referencia:"
                  description="Row header"
                />
              </div>
              <div className={cx(bigColumn, 'text-small-body-2 text-black')}>
                <FormattedMessage
                  id="BankTransferMethod.referenceValue"
                  defaultMessage={orderId}
                  description="Row header"
                />
              </div>
            </div>
          )}
          {!isCurrentRegionInMexico && (
            <div className="row pt-2">
              <div className="col-5 text-small-body-2 text-gray">
                <FormattedMessage
                  id="BankTransferMethod.taxpayer_id"
                  defaultMessage="RUC:"
                  description="Row header"
                />
              </div>
              <div className="col-7 text-small-body-2 text-black">
                <FormattedMessage
                  id="BankTransferMethod.bankName"
                  defaultMessage={bankAccount.taxpayer_id}
                  description="Interbank account bank name"
                />
              </div>
            </div>
          )}
          {!isCurrentRegionInMexico && (
            <div className="row pt-2">
              <div className="col-5 text-small-body-2 text-gray">
                <FormattedMessage
                  id="BankTransferMethod.accountNumber"
                  defaultMessage="Número de cuenta:"
                  description="Row header"
                />
              </div>
              <div className="col-7 text-small-body-2 text-black">
                <FormattedMessage
                  id="BankTransferMethod.bankName"
                  defaultMessage={bankAccount.account_number}
                  description="Interbank account bank name"
                />
              </div>
            </div>
          )}
          {!isCurrentRegionInMexico && (
            <div className="row pt-2">
              <div className="col-5 text-small-body-2 text-gray">
                <FormattedMessage
                  id="BankTransferMethod.reference"
                  defaultMessage="Referencia:"
                  description="Row header"
                />
              </div>
              <div className="col-7 text-small-body-2 text-black">
                <FormattedMessage
                  id="BankTransferMethod.referenceValue"
                  defaultMessage={bankAccount.reference}
                  description="Row header"
                />
              </div>
            </div>
          )}
          <p className={styles.transferInstructions}>
            Realiza la transferencia y envíanos el comprobante a
            info@gotandem.co junto con el número de orden {orderId}
          </p>
        </div>
      )}
    </div>
  )
}

export default BankTransferPaymentMethod
