import React from 'react'
import cx from 'classnames'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import Card from '@src/components/ui/Card'
import Image from '@src/components/ui/Image'

import background from './images/background.png'
import styles from '../SummaryScreen.module.scss'

const renderEmpty = () => {
  return (
    <Card className={styles.summaryCard}>
      <h3 className="text-small text-gray text-uppercase">
        <FormattedMessage
          id="NexteventsWidget.title"
          defaultMessage="Próximos Eventos"
          description="Balance title"
        />
      </h3>
      <div
        className={cx(
          'align-items-center d-flex pr-0 h-50',
          styles.summaryCardBody
        )}
      >
        <p className="text-black text-headline w-75">
          <FormattedMessage
            id="NextEventsWidget.emptyNotice"
            defaultMessage="Accede a los precios mas bajos en productos básicos"
            description="Next events empty notice"
          />
        </p>
        <Image
          background
          className={cx('w-25', styles.emptyNextEventsCardBackground)}
          src={background}
        />
      </div>
      <div className={styles.summaryAddBalance}>
        <NavLink to="/master-lists" className="text-body-2 link-blue">
          <FormattedMessage
            id="NextEventsWidget.emptyCTA"
            defaultMessage="Crea tu lista"
            description="Next events empty call to action"
          />
        </NavLink>
      </div>
    </Card>
  )
}

export default renderEmpty
