// @flow
import React, { Component } from 'react'

import cx from 'classnames'

type ButtonProps = {
  children: any,
  className?: string,
  primary?: boolean,
  secondary?: boolean,
  link?: boolean,
  small?: boolean,
  block?: boolean,
  cta?: boolean,
  medium?: boolean,
}

class Button extends Component<ButtonProps, *> {
  render() {
    const {
      primary,
      secondary,
      link,
      small,
      medium,
      block,
      className,
      children,
      cta,
      ...rest
    } = this.props

    const style = cx(
      'btn',
      {
        'btn-primary': primary,
        'btn-outline-secondary': secondary,
        'btn-link': link,
        'btn-default': !primary && !secondary && !link,
        'btn-sm': small,
        'btn-block': block,
        'btn-md': medium,
        'btn-cta': cta,
      },
      className
    )

    return (
      <button className={style} {...rest}>
        {children}
      </button>
    )
  }
}

export default Button
