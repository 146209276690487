// @flow
import React, { Component } from 'react'
import compose from 'lodash/flowRight'
import { camelCase, mapKeys } from 'lodash'
import { withRouter } from 'react-router-dom'

import { IntlProvider } from '@src/components/react-intl'
import graphql from './graphql'
import redux from './redux'
import Loading from './Loading'
import UIRoot from './UIRoot'
import * as errorReporter from '@src/lib/errorReporter'

type UIRootContainerProps = {
  data?: {
    loading: boolean,
    current_user?: {
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      region: {
        id: string,
        slug: string,
        name: string,
        time_zone: string,
      },
      companies: Array<{
        id: string,
        name: string,
        credit_period: number,
      }>,
      offices: Array<{
        id: string,
        name: string,
        monthly_budget: number,
        company_id: string,
        region: {
          id: string,
          slug: string,
          name: string,
          time_zone: string,
          currency: string,
        },
      }>,
    },
    currentOffice?: {
      id: string,
      monthly_budget: number,
    },
    currentCompany?: {
      id: string,
      credit_period: number,
    },
  },
  userIsLoggedIn: boolean,
  userPreferences: {
    currentCompanyId?: string,
    currentOfficeId?: string,
  },
  setCurrentCompanyId: (companyId: string) => void,
  setCurrentOfficeId: (officeId: string) => void,
  setCurrentRegionId: (regionId: string) => void,
  setCurrentRegion: (region: Object) => void,
}

type UIRootContainerState = {
  configuringI18n: boolean,
  language?: string,
  messages?: { [id: string]: string },
}

export class UIRootContainer extends Component<
  UIRootContainerProps,
  UIRootContainerState
> {
  componentDidUpdate(prevProps: UIRootContainerProps) {
    const previouslyLoading = prevProps.data && prevProps.data.loading
    const notLoadingNow = this.props.data && !this.props.data.loading
    const {
      data,
      setCurrentRegion,
      setCurrentCompanyId,
      setCurrentRegionId,
      setCurrentOfficeId,
    } = this.props
    let { currentCompanyId, currentOfficeId } = this.props.userPreferences

    if (previouslyLoading && notLoadingNow) {
      this.updateErrorReporterWithUserInfo()
    }

    if (!currentCompanyId) {
      const company =
        data && data.current_user && data.current_user.companies[0]

      if (company && company.id) {
        setCurrentCompanyId(company.id)
      }
    }

    if (currentOfficeId) {
      const office =
        data &&
        data.current_user &&
        data.current_user.offices.find(office => office.id === currentOfficeId)

      if (office && office.id) {
        setCurrentRegion(office.region)
      }
    } else {
      const office =
        data &&
        data.current_user &&
        data.current_user.offices.find(
          office => office.company_id === currentCompanyId
        )

      if (office && office.id) {
        setCurrentRegionId(office.region.id)
        setCurrentRegion(office.region)
        setCurrentOfficeId(office.id)
      }
    }
  }

  updateErrorReporterWithUserInfo() {
    if (this.props.data && this.props.data.current_user) {
      errorReporter.setUser(this.props.data.current_user.email)
    }
  }

  getLocaleFromRegionId(regionId?: string) {
    switch (regionId) {
      case '1':
      case '2':
        return 'es-MX'
      case '3':
        return 'es-PE'
      default:
        return 'es-MX'
    }
  }

  isLoading() {
    const tasks = [this.props.data && this.props.data.loading]

    const loading = task => task === true
    return tasks.some(loading)
  }

  render() {
    if (this.isLoading()) {
      return <Loading />
    }
    let { currentOfficeId, currentCompanyId } = this.props.userPreferences

    let data = this.props.data
    const region = data && data.current_user && data.current_user.region
    const locale = this.getLocaleFromRegionId(region && region.id)
    const timeZone = (region && region.time_zone) || 'America/Mexico_City'

    if (currentOfficeId) {
      const office =
        data &&
        data.current_user &&
        data.current_user.offices.find(office => office.id === currentOfficeId)

      if (data && office && office.id) {
        data.currentOffice = office
      }
    }

    if (currentCompanyId) {
      const company =
        data &&
        data.current_user &&
        data.current_user.companies.find(
          company => company.id === currentCompanyId
        )

      if (data && company && company.id) {
        data.currentCompany = company
      }
    }

    if (
      data &&
      !data.currentOffice &&
      data.current_user &&
      data.current_user.offices
    ) {
      let { setCurrentOfficeId, setCurrentCompanyId } = this.props

      const office = data && data.current_user && data.current_user.offices[0]

      if (data && office && office.id) {
        data.currentOffice = office
        setCurrentOfficeId(office.id)

        const company =
          data &&
          data.current_user &&
          data.current_user.companies.find(
            company => company.id === office.company_id
          )

        if (data && company && company.id) {
          setCurrentCompanyId(company.id)
          data.currentCompany = company
        }
      }
    }

    data = mapKeys(this.props.data, (v, k) => camelCase(k))

    return (
      <IntlProvider locale={locale} timeZone={timeZone}>
        <UIRoot initialData={data} />
      </IntlProvider>
    )
  }
}

export default compose(
  withRouter,
  redux,
  graphql
)(UIRootContainer)
