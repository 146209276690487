// @flow
import { connect } from 'react-redux'

import {
  isCurrentRegionInMexico,
  getCurrentRegion,
} from '@src/redux/selectors.js'

const select = state => ({
  isCurrentRegionInMexico: isCurrentRegionInMexico(state),
  currency: getCurrentRegion(state).currency,
})

export default connect(select)
