// @flow
import { connect } from 'react-redux'

import {
  setCurrentCompanyId,
  setCurrentOffice,
} from '@src/redux/modules/user/preferences'

const actions = {
  setCurrentCompanyId,
  setCurrentOffice,
}

export default connect(
  null,
  actions
)
