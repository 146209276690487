// @flow
import React, { Component } from 'react'
import compose from 'lodash/flowRight'
import { reduxForm, SubmissionError } from 'redux-form'

import CreateCreditCardModal from './CreateCreditCardModal'
import * as errorReporter from '@src/lib/errorReporter'
import PaymentTokensGenerator from '@src/lib/PaymentTokensGenerator'
import { separator as expirationDateSeparator } from '@src/components/ui/CreditCardExpirationDateInput/normalizeExpirationDate'
import graphql from './graphql'

type FormData = {
  creditCard: {
    name: string,
    number: string,
    expirationDate: string,
    cvc: string,
  },
}

type CreditCard = {|
  number: string,
  cvc: string,
  exp_month: string,
  exp_year: string,
  name: string,
|}

type CreateCreditCardModalContainerProps = {
  closeModal: () => void,
  officeId: string,

  // Provided by graphql
  createCreditCard: (
    officeId: string,
    conektaToken?: string,
    stripeToken?: string
  ) => Promise<{
    data: {
      create_credit_card: {
        id: string,
      },
    },
  }>,

  // Provided by redux-form
  handleSubmit: Function,
  submitting: boolean,
  error?: string,
}

export class CreateCreditCardModalContainer extends Component<
  CreateCreditCardModalContainerProps,
  *
> {
  handleSubmit: (formData: FormData) => Promise<void>

  constructor(props: CreateCreditCardModalContainerProps) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async handleSubmit(formData: FormData) {
    const creditCardDetails = this.getCreditCardFromFormData(formData)
    const paymentTokens = await this.generatePaymentTokens(creditCardDetails)

    await this.createCreditCard(
      this.props.officeId,
      paymentTokens.conekta,
      paymentTokens.stripe
    )

    this.props.closeModal()
  }

  getCreditCardFromFormData(formData: FormData): CreditCard {
    const getMonth = (expirationDate: string) =>
      expirationDate.split(expirationDateSeparator, 2)[0]
    const getYear = (expirationDate: string) =>
      expirationDate.split(expirationDateSeparator, 2)[1]

    const { creditCard } = formData

    return {
      name: creditCard.name,
      number: creditCard.number,
      cvc: creditCard.cvc,
      exp_month: getMonth(creditCard.expirationDate),
      exp_year: getYear(creditCard.expirationDate),
    }
  }

  async generatePaymentTokens(card: CreditCard) {
    const tokensGenerator = new PaymentTokensGenerator(card)
    const paymentTokens = await tokensGenerator.generateTokens()

    const conekta = paymentTokens.conekta.token
    const stripe = paymentTokens.stripe.token

    return {
      conekta,
      stripe,
    }
  }

  async createCreditCard(
    officeId: string,
    conektaToken?: string,
    stripeToken?: string
  ) {
    try {
      const createCreditCardResponse = await this.props.createCreditCard(
        officeId,
        conektaToken,
        stripeToken
      )
      return createCreditCardResponse.data.create_credit_card
    } catch (e) {
      errorReporter.log(e)
      return this.throwGenericError()
    }
  }

  throwGenericError() {
    throw new SubmissionError({
      _error: 'Hubo un error creando la tarjeta. Por favor intente más tarde.',
    })
  }

  render() {
    return (
      <CreateCreditCardModal
        onSubmit={this.props.handleSubmit(this.handleSubmit)}
        submitting={this.props.submitting}
        error={this.props.error}
      />
    )
  }
}

export default compose(
  graphql,
  reduxForm({
    form: 'addPaymentMethodModal',
  })
)(CreateCreditCardModalContainer)
