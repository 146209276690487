// @flow
import * as creditCard from '@src/utils/creditCard'

export function validCreditCardNumber(value: string) {
  const valid = creditCard.valid.number(value).isValid

  if (!valid) {
    return 'El número de tarjeta no es valido'
  }
}
