// @flow
import React from 'react'
import { FormattedMessage } from 'react-intl'

import styles from './RequestMasterListModal.module.scss'
import Button from '@src/components/ui/Button'

type RequestMasterListModalProps = {
  approver: ?{
    first_name: string,
    last_name: string,
    email: string,
  },
  requesting: boolean,
  onSubmit: () => void,
}

export default function RequestMasterListModal(
  props: RequestMasterListModalProps
) {
  const { approver, onSubmit, requesting } = props

  if (approver) {
    const approverFullName = approver.first_name + ' ' + approver.last_name
    const approverEmail = approver.email

    return (
      <div className={styles.container}>
        <h1 className="text-title">
          <FormattedMessage
            id="RequestMasterListModal.title"
            defaultMessage="Confirmación de solicitud"
            description="Title"
          />
        </h1>

        <p className="d-flex justify-content-between">
          <FormattedMessage
            id="RequestMasterListModal.requestSuccessModalBody"
            defaultMessage="¿Deseas enviar una solicitud de aprobación a {approverFullName} al correo {approverEmail} para que apruebe la lista?"
            description="Body"
            values={{
              approverFullName: <b>{approverFullName}</b>,
              approverEmail: <b>{approverEmail}</b>,
            }}
          />
        </p>

        <div className={styles.buttons}>
          <Button cta onClick={onSubmit} disabled={requesting}>
            {requesting ? (
              <FormattedMessage
                id="RequestMasterListModal.submitButtonWhileRequesting"
                defaultMessage="Solicitando..."
                description="Submit button while requesting"
              />
            ) : (
              <FormattedMessage
                id="RequestMasterListModal.submitButton"
                defaultMessage="Enviar solicitud"
                description="Submit button"
              />
            )}
          </Button>
        </div>
      </div>
    )
  } else {
    return (
      <div className={styles.container}>
        <h1 className="text-title">
          <FormattedMessage
            id="RequestMasterListModal.title"
            defaultMessage="Falta aprobador"
            description="Title"
          />
        </h1>

        <p>Lo sentimos no hay nadie que pueda aprobar esta lista.</p>
        <p>
          Pide ayuda en <a href="mailto: info@gotandem.co">info@gotandem.co</a>
        </p>
      </div>
    )
  }
}
