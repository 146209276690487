// @flow
import React from 'react'
import cx from 'classnames'

import logo from './images/tandem-logo.svg'
import styles from './Logo.module.scss'
import Image from '@src/components/ui/Image'

type LogoProps = {
  className?: string,
}

export default function Logo({ className }: LogoProps) {
  return <Image className={cx(styles.logo, className)} src={logo} />
}
