// @flow
import React from 'react'
import cx from 'classnames'

import styles from './ImageTitleBodyButtonModal.module.scss'
import Image from '@src/components/ui/Image'
import Button from '@src/components/ui/Button'
import FormValidationError from '@src/components/ui/FormValidationError'

import type { Node as ReactNode } from 'react'

type ImageTitleBodyButtonModalProps = {
  image: string,
  title: string,
  body: ReactNode,
  buttonText: string,
  buttonDisabled: boolean,
  error?: string,
  onClick: () => void,
}

export default function ImageTitleBodyButtonModal(
  props: ImageTitleBodyButtonModalProps
) {
  const {
    image,
    title,
    body,
    buttonText,
    buttonDisabled,
    error,
    onClick: handleClick,
  } = props

  return (
    <div className={styles.container}>
      <Image className={styles.image} src={image} />

      <h2 className={cx('text-title', styles.title)}>{title}</h2>

      {typeof body === 'string' ? (
        <p className={cx(styles.body)}>{body}</p>
      ) : (
        <div className={cx(styles.body)}>{body}</div>
      )}

      <Button cta onClick={handleClick} disabled={buttonDisabled}>
        {buttonText}
      </Button>

      {error && <FormValidationError className={styles.error} error={error} />}
    </div>
  )
}
