// @flow
import Loadable from 'react-loadable'

import Loader from '@src/components/ui/ReactLoadableLoader'

const Error404Loader = Loadable({
  loader: () => import('./Error404'),
  loading: Loader,
})

export default Error404Loader
