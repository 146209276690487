// @flow
import React, { Component } from 'react'
import compose from 'lodash/flowRight'
import { SubmissionError } from 'redux-form'

import RequestProductModal from './RequestProductModal'
import RequestSuccessModal from './RequestSuccessModal'
import graphql from './graphql'
import * as errorReporter from '@src/lib/errorReporter'

import type { RequestProductInput } from './graphql'

type RequestProductModalContainerProps = {
  closeModal: () => void,
  officeId: string,
  requestProduct: (requestProductInput: RequestProductInput) => Promise<void>,
}

type FormData = {
  title: string,
  description: string,
  link: string,
}

export class RequestProductModalContainer extends Component<
  RequestProductModalContainerProps,
  *
> {
  handleSubmit: Function
  handleSubmitSuccess: Function

  constructor(props: RequestProductModalContainerProps) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitSuccess = this.handleSubmitSuccess.bind(this)
    this.state = {
      displayRequestSuccessModal: false,
    }
  }

  async handleSubmit(formData: FormData) {
    const { officeId } = this.props

    try {
      await this.requestProduct(officeId, formData)
    } catch (e) {
      errorReporter.log(e)
      throw new SubmissionError({
        _error:
          'Hubo un error solitando tu producto. Por favor vuelve a intentar.',
      })
    }
  }

  handleSubmitSuccess() {
    this.setState(() => ({ displayRequestSuccessModal: true }))
  }

  requestProduct(officeId: string, formData: FormData): Promise<void> {
    return this.props.requestProduct({
      officeId,
      title: formData.title,
      description: formData.description,
      link: formData.link,
    })
  }

  render() {
    return this.state.displayRequestSuccessModal ? (
      <RequestSuccessModal closeModal={this.props.closeModal} />
    ) : (
      <RequestProductModal
        onSubmit={this.handleSubmit}
        onSubmitSuccess={this.handleSubmitSuccess}
      />
    )
  }
}

export default compose(graphql)(RequestProductModalContainer)
