// @flow
import Loadable from 'react-loadable'

import Loader from '@src/components/ui/ReactLoadableLoader'

const ResetPasswordLoader = Loadable({
  loader: () => import('./ResetPasswordScreenContainer'),
  loading: Loader,
})

export default ResetPasswordLoader
