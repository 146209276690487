// @flow
export function getApiHostname(): string {
  if (process.env.NODE_ENV === 'development') {
    return ''
  }

  return process.env.REACT_APP_API_HOSTNAME || ''
}

export function getApiPort(): string {
  if (process.env.NODE_ENV === 'development') {
    return ''
  }

  if (!getApiHostname()) {
    return ''
  }

  return process.env.REACT_APP_API_PORT || ''
}

export function getApiRoot(): string {
  return process.env.REACT_APP_API_ROOT || '/'
}

export function getApiUrl(): string {
  const REACT_APP_API_HOSTNAME = getApiHostname()
  const REACT_APP_API_PORT = getApiPort() ? `:${getApiPort()}` : ''
  const REACT_APP_API_ROOT = getApiRoot()

  return `${REACT_APP_API_HOSTNAME}${REACT_APP_API_PORT}${REACT_APP_API_ROOT}`
}
