// @flow
import { Component } from 'react'

export default class SingleDatePickerFocusHelper extends Component<*, *> {
  handleFocusChange: Function

  constructor(props: *) {
    super(props)

    this.state = {
      focused: false,
    }

    this.handleFocusChange = this.handleFocusChange.bind(this)
  }

  handleFocusChange({ focused }: { focused: boolean }) {
    this.setState(() => ({ focused }))
  }

  render() {
    return this.props.children(this.state.focused, this.handleFocusChange)
  }
}
