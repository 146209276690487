// @flow
import React, { Component } from 'react'
import compose from 'lodash/flowRight'
import gql from 'graphql-tag'
import { reduxForm, SubmissionError } from 'redux-form'
import { injectIntl } from 'react-intl'

import NewMasterListModal from './NewMasterListModal'
import * as errorReporter from '@src/lib/errorReporter'

import type { MessageDescriptor } from 'react-intl'

type MasterList = {
  id: string,
}

type MasterListInput = {
  name: string,
}

type FormData = {
  name: string,
  periodicity: string,
}

type NewMasterListModalContainerProps = {
  officeId: string,
  createMasterList: (
    officeId: string,
    masterListInput: MasterListInput
  ) => Promise<{
    data: {
      create_master_list: MasterList,
    },
  }>,
  handleSubmit: ((formData: FormData) => Promise<void>) => Function,
  submitting: boolean,
  error?: any,
  intl: {
    formatMessage: (
      messageDescriptor: MessageDescriptor,
      values?: Object
    ) => string,
  },
}

export class NewMasterListModalContainer extends Component<
  NewMasterListModalContainerProps,
  *
> {
  formData: FormData

  static fragments: {
    newMasterList: string,
  }

  handleSubmit: (formData: FormData) => Promise<void>

  constructor(props: NewMasterListModalContainerProps) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async handleSubmit(formData: FormData) {
    this.formData = formData

    const masterListInput = this.getMasterListInput()

    try {
      const response = await this.createMasterList(
        this.props.officeId,
        masterListInput
      )
      this.goToMasterList(response.data.create_master_list.id)
    } catch (e) {
      errorReporter.log(e)
      throw new SubmissionError({ _error: e.message })
    }
  }

  goToMasterList(id: string) {
    window.location.assign(`/master-lists/${id}/`)
  }

  createMasterList(officeId: string, masterListInput: MasterListInput) {
    return this.props.createMasterList(officeId, masterListInput)
  }

  getMasterListInput(): MasterListInput {
    return {
      name: this.formData.name,
      periodicity: this.formData.periodicity,
    }
  }

  render() {
    return (
      <NewMasterListModal
        error={this.props.error}
        submitting={this.props.submitting}
        handleSubmit={this.props.handleSubmit(this.handleSubmit)}
        intl={this.props.intl}
      />
    )
  }
}

NewMasterListModalContainer.fragments = {
  newMasterList: gql`
    fragment NewMasterList on MasterList {
      id
    }
  `,
}

export default compose(
  injectIntl,
  reduxForm({
    form: 'newMasterList',
    initialValues: {
      name: 'Lista semanal',
    },
  })
)(NewMasterListModalContainer)
