// @flow
import React from 'react'

import styles from './WidgetLoader.module.scss'

export default function WidgetLoader() {
  return (
    <div>
      <div className="col">
        <div className={styles.loadingHeader} />
        <div className={styles.loading} />
      </div>
    </div>
  )
}
