// @flow
import React from 'react'
import gql from 'graphql-tag'
import { injectIntl, defineMessages } from 'react-intl'
import cx from 'classnames'

import emptyBasket from './images/empty-basket.svg'
import Card from '@src/components/ui/Card'
import Image from '@src/components/ui/Image'

import styles from '../ProductCard/ProductCard.module.scss'

type RequestedProduct = {
  title: string,
}

type RequestedProductCardProps = {
  className?: string,
  product: RequestedProduct,
  intl: *,
}

export function RequestedProductCard(props: RequestedProductCardProps) {
  const {
    product,
    className,
    intl: { formatMessage },
  } = props

  const messages = defineMessages({
    pendingApproval: {
      id: 'RequestedProductCard.pendingApproval',
      defaultMessage: 'Pendiente de aprobación',
      description: 'Messages informing the request is pending approval.',
    },
  })

  return (
    <Card clickable className={cx(styles.productCard, className)}>
      <div className={styles.imageWrapper}>
        <Image className={styles.image} src={emptyBasket} />
      </div>

      <h2 className={cx('text-body-2', styles.title)}>{product.title}</h2>

      <p className={cx('text-small-body text-dark', styles.unit)}>
        {formatMessage(messages.pendingApproval)}
      </p>
    </Card>
  )
}

RequestedProductCard.fragments = {
  product: gql`
    fragment RequestedProductCardProduct on RequestedProduct {
      id
      title
    }
  `,
}

export default injectIntl(RequestedProductCard)
