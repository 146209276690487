// @flow
import React from 'react'

import InputFormGroupField from '@src/components/ui/Input'
import normalizeExpirationDate from './normalizeExpirationDate'
import { validExpirationDate } from './validations'

type CreditCardExpirationDateInputProps = {
  name: string,
  validate?: Function | Array<Function>,
}

export default function CreditCardExpirationDateInputFormGroupField(
  props: CreditCardExpirationDateInputProps
) {
  let validations = [validExpirationDate]

  if (props.validate) {
    validations = validations.concat(props.validate)
  }

  return (
    <InputFormGroupField
      placeholder="MM / YY"
      {...props}
      validate={validations}
      normalize={normalizeExpirationDate}
    />
  )
}
