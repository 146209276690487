// @flow
import React from 'react'
import cx from 'classnames'
import compose from 'lodash/flowRight'
import { reduxForm } from 'redux-form'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import styles from './RequestProductModal.module.scss'
import Input from '@src/components/ui/Input'
import TextArea from '@src/components/ui/TextArea'
import Button from '@src/components/ui/Button'
import FullScreenLoader from '@src/components/ui/FullScreenLoader'
import FormValidationError from '@src/components/ui/FormValidationError'
import { isRequired } from '@src/utils/validation'

const messages = defineMessages({
  titleLabel: {
    id: 'RequestProductModal.titleLabel',
    defaultMessage: 'Nombre del producto',
    description: 'Title input label',
  },

  titlePlaceholder: {
    id: 'RequestProductModal.titlePlaceholder',
    defaultMessage: 'Nombra tu solicitud, ej. Dulces',
    description: 'Title input placeholder text',
  },

  descriptionLabel: {
    id: 'RequestProductModal.descriptionLabel',
    defaultMessage: 'Describe tu solicitud',
    description: 'Description input label',
  },

  descriptionPlaceholder: {
    id: 'RequestProductModal.descriptionPlaceholder',
    defaultMessage:
      'Danos todos los detalles específicos del producto, incluyendo la marca y unidad de medida si lo requiere.',
    description: 'Description input placeholder text',
  },

  linkLabel: {
    id: 'RequestProductModal.linkLabel',
    defaultMessage: 'Link de referencia (opcional)',
    description: 'Link input label',
  },

  linkPlaceholder: {
    id: 'RequestProductModal.linkPlaceholder',
    defaultMessage: 'Ejemplo: https://www.amazon.com/gp/product/154…',
    description: 'Link input placeholder text',
  },
})

type RequestProductModalProps = {
  handleSubmit: () => void,
  submitting: boolean,
  error?: string,
  intl: *,
}

export function RequestProductModal(props: RequestProductModalProps) {
  const {
    intl: { formatMessage },
  } = props

  return (
    <div className={styles.container}>
      {props.submitting && <FullScreenLoader />}

      <h1 className={cx('text-title', styles.title)}>
        <FormattedMessage
          id="RequestProductModal.title"
          defaultMessage="Solicitar producto"
          description="Title of the modal"
        />
      </h1>

      <p className="text-dark">
        <FormattedMessage
          id="RequestProductModal.description"
          defaultMessage="¿Te gustaría agregar un producto o algo que no encuentras en la lista? Déjanos saber y nosotros nos encargamos de conseguirlo."
          description="Description of the modal"
        />
      </p>

      <form className={styles.form} onSubmit={props.handleSubmit}>
        <Input
          name="title"
          label={formatMessage(messages.titleLabel)}
          placeholder={formatMessage(messages.titlePlaceholder)}
          validate={[isRequired]}
        />

        <TextArea
          name="description"
          label={formatMessage(messages.descriptionLabel)}
          placeholder={formatMessage(messages.descriptionPlaceholder)}
          rows={4}
          validate={[isRequired]}
        />

        <Input
          name="link"
          label={formatMessage(messages.linkLabel)}
          placeholder={formatMessage(messages.linkPlaceholder)}
        />

        {props.error && <FormValidationError error={props.error} />}

        <Button className={styles.submitButton} disabled={props.submitting}>
          {props.submitting ? (
            <FormattedMessage
              id="RequestProductModal.submitButtonWhileSubmitting"
              defaultMessage="Solicitando..."
              description="Submit button while submitting"
            />
          ) : (
            <FormattedMessage
              id="RequestProductModal.submitButton"
              defaultMessage="Solicitar"
              description="Submit button"
            />
          )}
        </Button>
      </form>
    </div>
  )
}

export default compose(
  reduxForm({
    form: 'RequestProductForm',
  }),
  injectIntl
)(RequestProductModal)
