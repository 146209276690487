// @flow
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'

import { RequestedProductCard } from '@src/components/ui/RequestedProductCard/RequestedProductCard'

export type RequestProductInput = {
  officeId: string,
  title: string,
  description: string,
  link: string,
}

const OfficeRequestedProductsQuery = gql`
  query OfficeRequestedProducts($officeId: ID!) {
    office(id: $officeId) {
      id
      requested_products {
        id
      }
    }
  }
`

const withRequestProduct = graphql(
  gql`
    mutation RequestProduct(
      $officeId: ID!
      $title: String!
      $description: String!
      $link: String
    ) {
      request_product(
        office_id: $officeId
        title: $title
        description: $description
        link: $link
      ) {
        id
        ...RequestedProductCardProduct
      }
    }
    ${RequestedProductCard.fragments.product}
  `,
  {
    name: 'requestProductMutation',
    props: ({ requestProductMutation }) => ({
      requestProduct: ({
        officeId,
        title,
        description,
        link,
      }: RequestProductInput) =>
        requestProductMutation({
          variables: { officeId, title, description, link },
          update: (proxy, { data: { request_product: requestedProduct } }) => {
            const cachedData = proxy.readQuery({
              query: OfficeRequestedProductsQuery,
              variables: { officeId },
            })

            cachedData.office.requested_products.push(requestedProduct)

            proxy.writeQuery({
              query: OfficeRequestedProductsQuery,
              variables: { officeId },
              data: cachedData,
            })
          },
        }),
    }),
  }
)

export default compose(withRequestProduct)
