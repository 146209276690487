// @flow
import React from 'react'
import { Route } from 'react-router-dom'

import Redirect from '@src/components/react-router/Redirect'
import { isLoggedIn } from '@src/utils/authorization'
import * as qs from '@src/utils/querystring'

import type { ComponentType } from 'react'
import type { ContextRouter } from 'react-router'

type AuthRouteProps = {
  component: ComponentType<*>,
}

export default function AuthRoute(props: AuthRouteProps) {
  const { component: Component, ...rest } = props

  return (
    <Route
      {...rest}
      render={(props: ContextRouter) => {
        if (isLoggedIn()) {
          return <Component {...props} />
        } else {
          const url = '/login'
          const querystring = qs.stringify({
            redirectTo: props.location.pathname,
          })

          return <Redirect to={`${url}${querystring}`} />
        }
      }}
    />
  )
}
