// @flow
import React, { Component } from 'react'

import { log as logError } from '@src/lib/errorReporter'
import Error500 from '@src/components/500/Error500'

export default class AppErrorBoundary extends Component<*, *> {
  constructor() {
    super()

    this.state = {
      error: undefined,
    }
  }
  componentDidCatch(error: Error, info: Object) {
    logError(error, info)
    this.setState({ error })
  }

  render() {
    return this.state.error ? <Error500 /> : this.props.children
  }
}
