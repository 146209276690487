// @flow
import React from 'react'

import { isLoggedIn } from '@src/utils/authorization'
import Header from './Header'
import HeaderContainer from './HeaderContainer'

export default function HeaderSelector(props: Object) {
  const FinalHeader = isLoggedIn() ? HeaderContainer : Header

  return <FinalHeader {...props} />
}
