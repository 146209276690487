// @flow
import React from 'react'
import { Redirect as ReactRouterRedirect, Route } from 'react-router-dom'

type RedirectProps = {
  from?: string,
  to: string,
}

export default function Redirect(props: RedirectProps) {
  const { from, to, ...rest } = props

  if (!from) {
    return <ReactRouterRedirect {...props} />
  }

  return (
    <Route
      path={from}
      render={({ match }) => {
        const paramKeys = match.params && Object.keys(match.params)

        const toWithReplacedParamsKeys = paramKeys
          ? paramKeys.reduce(
              (url, key) => url.replace(`:${key}`, match.params[key]),
              to
            )
          : to

        return <ReactRouterRedirect to={toWithReplacedParamsKeys} {...rest} />
      }}
    />
  )
}
