// @flow
import React, { Component } from 'react'
import cx from 'classnames'
import { injectIntl, defineMessages } from 'react-intl'
import Geosuggest from 'react-geosuggest'

import styles from './GeoSearchControl.module.scss'

import type { IntlShape } from 'react-intl'

const messages = defineMessages({
  placeholder: {
    id: 'GeoSearchControl.placeholder',
    defaultMessage: 'Escribe una dirección',
    description: 'Default placehodler text for the geographical search input',
  },
})

type GeoSearchControlProps = {
  className?: string,
  onChange: (*) => void,
  value: any,
  onBlur: any => void,
  placeholder?: string,
  country?: string | Array<string>,
  location?: *,
  radius?: number,
  intl: IntlShape,
}

export class GeoSearchControl extends Component<GeoSearchControlProps, *> {
  handleBlur: Function
  handleSelectSuggest: Function

  static defaultProps: *

  constructor(props: GeoSearchControlProps) {
    super(props)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleSelectSuggest = this.handleSelectSuggest.bind(this)
  }

  handleSelectSuggest(suggest: Object) {
    if (suggest === undefined) {
      return null
    }
    this.props.onChange(suggest.label ? suggest : undefined)
  }

  handleBlur(value: string) {
    this.props.onBlur(value ? this.props.value : undefined)
  }

  render() {
    const {
      className,
      placeholder,
      intl,
      onChange, // Selecting this value to avoid placing it inside ...rest
      onBlur, // Selecting this value to avoid placing it inside ...rest
      ...rest
    } = this.props

    return (
      <Geosuggest
        onSuggestSelect={this.handleSelectSuggest}
        // $FlowIgnore
        className={cx(styles.container, { 'is-invalid': rest.meta.invalid })}
        inputClassName={cx(styles.input, className)}
        suggestsClassName={cx(styles.suggestionsList, 'text-small-body')}
        suggestsHiddenClassName={cx(styles.hiddenSuggestionsList)}
        suggestItemClassName={cx(styles.suggestionItem)}
        suggestItemActiveClassName={cx(styles.activeSuggestionItem)}
        placeholder={placeholder || intl.formatMessage(messages.placeholder)}
        onBlur={this.handleBlur}
        {...rest}
      />
    )
  }
}

GeoSearchControl.defaultProps = {
  country: ['mx', 'pe'],
}

export default injectIntl(GeoSearchControl)
