// @flow
import React, { Component } from 'react'
import compose from 'lodash/flowRight'
import { reduxForm, SubmissionError } from 'redux-form'

import InviteUserToOfficeModal from './InviteUserToOfficeModal'
import graphql from './graphql'
import { isEmail } from '@src/utils/validation'
import * as errorReporter from '@src/lib/errorReporter'
import { DEFAULT_ROLE } from '@src/constants/userRoles'

type FormData = {
  invitations: Array<{
    email: string,
    role: string,
  }>,
}

type InviteUserToOfficeModalContainerProps = {
  office: Object,
  closeModal: () => void,
  inviteUser: (officeId: string, email: string, role: string) => Promise<void>,

  // Redux form props
  handleSubmit: Function,
  submitting: boolean,
  error?: string,
  reset: Function,
}

export class InviteUserToOfficeModalContainer extends Component<
  InviteUserToOfficeModalContainerProps,
  *
> {
  handleSubmit: (formData: FormData) => Promise<void>

  constructor(props: InviteUserToOfficeModalContainerProps) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = { error: '' }
  }

  async handleSubmit(formData: FormData) {
    const validInvitations = []
    formData.invitations.forEach(invitation => {
      const hasError = isEmail(invitation.email)

      if (!hasError) {
        if (!invitation.role) {
          invitation.role = DEFAULT_ROLE
        }
        validInvitations.push(invitation)
      }
    })

    try {
      const inviteUserPromises = validInvitations.map(invitation =>
        this.inviteUser(invitation)
      )
      await Promise.all(inviteUserPromises)
    } catch (e) {
      this.setState(() => ({ error: e.graphQLErrors[0].message }))

      errorReporter.log(e)
      throw new SubmissionError({
        _error: 'Hubo un error. Por favor vuelve a intentar.',
      })
    }

    this.props.closeModal()
  }

  inviteUser(invitation: { email: string, role: string }) {
    return this.props.inviteUser(
      this.props.office.id,
      invitation.email,
      invitation.role
    )
  }

  render() {
    const { handleSubmit, submitting } = this.props
    const { error } = this.state

    return (
      <InviteUserToOfficeModal
        onSubmit={handleSubmit(this.handleSubmit)}
        submitting={submitting}
        error={error}
      />
    )
  }
}

export default compose(
  graphql,
  reduxForm({
    form: 'InviteUserToOfficeModal',
  })
)(InviteUserToOfficeModalContainer)
