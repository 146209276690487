// @flow
import React from 'react'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'

import PaymentMethodsEmpty from './PaymentMethodsEmpty'
import Button from '@src/components/ui/Button'
import Card from '@src/components/ui/Card'
import Icon from '@src/components/ui/Icon'
import FullScreenLoader from '@src/components/ui/FullScreenLoader'
import styles from './PaymentMethodsScreen.module.scss'

type PaymentMethodsScreenProps = {
  loading: boolean,
  onClickCreateCreditCard: () => void,
  onClickDeleteCreditCard: (creditCardId: string) => void,
  creditCards?: Array<{
    id: string,
    name: string,
    last4: string,
  }>,
  deletingCreditCard: boolean,
}

export default function PaymentMethodsScreen(props: PaymentMethodsScreenProps) {
  if (props.loading) {
    return null
  }

  return (
    <div className="container">
      {props.deletingCreditCard && <FullScreenLoader />}

      <div className="row">
        <div className="col">
          <div className={cx('text-right', styles.topButton)}>
            <Button cta onClick={props.onClickCreateCreditCard}>
              <FormattedMessage
                id="PaymentMethodsScreen.addPaymentMethodButton"
                defaultMessage="AGREGAR MÉTODO DE PAGO"
                description="Add new payment method button text"
              />
            </Button>
          </div>

          {!props.creditCards || props.creditCards.length === 0 ? (
            <div className="d-flex justify-content-center">
              <PaymentMethodsEmpty />
            </div>
          ) : (
            <ul className={cx('list-unstyled', styles.creditCardsList)}>
              {props.creditCards &&
                props.creditCards.map(creditCard => (
                  <li
                    key={creditCard.id}
                    className={styles.creditCardsListItem}
                  >
                    <Card className={styles.creditCard}>
                      <h1 className={cx('text-title-3', styles.cardTitle)}>
                        Método de pago
                      </h1>

                      <div className={styles.methodWrapper}>
                        <div>
                          <p>{creditCard.name}</p>

                          <p className="text-secondary">
                            **** **** **** {creditCard.last4}
                          </p>
                        </div>

                        <Button
                          secondary
                          onClick={() =>
                            props.onClickDeleteCreditCard(creditCard.id)
                          }
                        >
                          <Icon className={styles.trashIcon} name="trash" />

                          <FormattedMessage
                            id="PaymentMethodsScreen.deleteCreditCardButton"
                            defaultMessage="ELIMINAR"
                            description="Delete credit card button text"
                          />
                        </Button>
                      </div>
                    </Card>
                  </li>
                ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

PaymentMethodsScreen.fragments = {
  creditCard: gql`
    fragment PaymentMethodsScreenCreditCard on CreditCard {
      id
      name
      last4
    }
  `,
}
