// @flow
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'

const requestMasterListApprovalMutation = graphql(
  gql`
    mutation requestMasterListApprovalMutation($id: ID!) {
      request_master_list_approval(id: $id) {
        id
        waiting_for_approval
      }
    }
  `,
  {
    name: 'requestMasterListApprovalMutation',
    props: ({ requestMasterListApprovalMutation }) => ({
      requestMasterListApprovalMutation: (id: string) =>
        requestMasterListApprovalMutation({
          variables: { id },
        }),
    }),
  }
)

export default compose(requestMasterListApprovalMutation)
