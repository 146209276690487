// @flow
export const BASE: string = process.env.REACT_APP_NAME || 'tandem'

const createStorageKey = key => `${BASE}:${key}`

export const AUTH_TOKEN = createStorageKey('authToken')
export const CURRENT_COMPANY_ID = createStorageKey('currentCompanyId')
export const CURRENT_OFFICE_ID = createStorageKey('currentOfficeId')
export const CURRENT_REGION_ID = createStorageKey('currentRegionId')
export const REFERRAL_UTM = createStorageKey('referralUtm')
