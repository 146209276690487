// @flow
import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'

import styles from './Sidebar.module.scss'
import Icon from '@src/components/ui/Icon'

import type { ContextRouter } from 'react-router'

type SidebarProps = {
  ...ContextRouter,
}

export class Sidebar extends Component<SidebarProps, *> {
  render() {
    const { className, homeServicesPeru, isPeru, companyEditable } = this.props

    const navClassName = cx('nav', 'flex-column', className)

    return (
      <ul className={navClassName}>
        <li className="nav-item">
          <NavLink
            exact
            to="/"
            className={cx('nav-link', styles.navBarItem)}
            activeClassName="active"
          >
            <Icon className={styles.icon} name="home" />

            <FormattedMessage
              id="Sidebar.menuItemSart"
              defaultMessage="Inicio"
              description="Link to the main dashboard view"
            />
          </NavLink>
        </li>

        {!isPeru && (
          <li className="nav-item">
            <NavLink
              exact
              to="/master-lists"
              className={cx('nav-link', styles.navBarItem)}
              activeClassName="active"
            >
              <Icon className={styles.icon} name="box" />

              <FormattedMessage
                id="Sidebar.menuItemProductsQuoter"
                defaultMessage="Productos"
                description="Link to the products quoter page"
              />
            </NavLink>
          </li>
        )}

        <li className="nav-item">
          <NavLink
            to="/orders"
            className={cx('nav-link', styles.navBarItem)}
            activeClassName="active"
          >
            <Icon className={styles.icon} name="basket" />

            <FormattedMessage
              id="Sidebar.menuItemOrders"
              defaultMessage="Órdenes"
              description="Link to the orders page"
            />
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/reports"
            className={cx('nav-link', styles.navBarItem)}
            activeClassName="active"
          >
            <Icon className={styles.icon} name="report" />

            <FormattedMessage
              id="Sidebar.menuItemReports"
              defaultMessage="Reportes"
              description="Link to the reports page"
            />
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/balance"
            className={cx('nav-link', styles.navBarItem)}
            activeClassName="active"
          >
            <Icon className={styles.icon} name="clock" />

            <FormattedMessage
              id="Sidebar.menuItemBalance"
              defaultMessage="Historial"
              description="Link to the balance page"
            />
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/invoices"
            className={cx('nav-link', styles.navBarItem)}
            activeClassName="active"
          >
            <Icon className={styles.icon} name="invoices" />

            <FormattedMessage
              id="Sidebar.menuItemInvoices"
              defaultMessage="Facturas"
              description="Link to the invoices page"
            />
          </NavLink>
        </li>

        {!homeServicesPeru && (
          <li className="nav-item">
            <NavLink
              to="/my-location"
              className={cx('nav-link', styles.navBarItem)}
              activeClassName="active"
            >
              <Icon className={styles.icon} name="pin" />

              <FormattedMessage
                id="Sidebar.menuItemMyLocation"
                defaultMessage="Mi Ubicación"
                description="Link to the my location page"
              />
            </NavLink>
          </li>
        )}

        {!homeServicesPeru &&
          companyEditable && (
            <li className="nav-item">
              <NavLink
                to="/my-company"
                className={cx('nav-link', styles.navBarItem)}
                activeClassName="active"
              >
                <Icon className={styles.icon} name="briefcase" />

                <FormattedMessage
                  id="Sidebar.menuItemMyCompany"
                  defaultMessage="Mi organización"
                  description="Link to the my company page"
                />
              </NavLink>
            </li>
          )}

        {homeServicesPeru && (
          <li className="nav-item">
            <NavLink
              to="/my-company/add-balance"
              className={cx('nav-link', styles.navBarItem)}
              activeClassName="active"
            >
              <Icon className={styles.icon} name="wallet" />

              <FormattedMessage
                id="Sidebar.menuItemMyCompany"
                defaultMessage="Pago"
                description="Link to add balance page"
              />
            </NavLink>
          </li>
        )}
      </ul>
    )
  }
}

export default withRouter(Sidebar)
