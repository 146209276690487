// @flow
import {
  createActionType as createActionTypeCreator,
  action as createAction,
  reducer as createReducer,
} from '@src/redux/utils'

const createActionType = createActionTypeCreator('User region')

export const SET_REGION = createActionType('SET_REGION')

export const setRegion = (region: Object) => createAction(SET_REGION, region)

const initialState: Object = {}

const reducer = createReducer(
  {
    [SET_REGION]: (state, action) => action.payload,
  },
  initialState
)

export default reducer
