// @flow
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import httpStatus from 'http-status'

import { getApiUrl } from '@src/lib/api/utils'
import { get as getAuthToken } from '@src/utils/authToken'
import { logOut } from '@src/utils/authorization'
import { get as getRegionId } from '@src/utils/region'

const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT || 'graphql'
const API_URL = `${getApiUrl()}/${GRAPHQL_ENDPOINT}`

// $FlowIgnore
const httpLink = new HttpLink({ uri: API_URL })

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken()
  const region = getRegionId()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'X-TANDEM-REGION': region || '',
    },
  }
})

const unauthorizedLink = onError(({ networkError }) => {
  if (
    networkError &&
    networkError.response &&
    networkError.response.status === httpStatus.UNAUTHORIZED
  ) {
    logOut()
    window.location.replace('/login')
  }
})

const fragmentMatcher = new IntrospectionFragmentMatcher({
  /**
    TODO: Right now this is hardcoded, and if the graphql schema changes,
    it would need to be updated to the new values. But we can do this with a script.
    https://github.com/go-tandem/tandem-dashboard/issues/170
  **/
  introspectionQueryResultData: {
    __schema: {
      types: [],
    },
  },
})

const client = new ApolloClient({
  link: ApolloLink.from([unauthorizedLink, authLink, httpLink]),
  cache: new InMemoryCache({ fragmentMatcher }),
})

export default client
