// @flow
export function log(error: Error, customData?: *) {
  if (window.rg4js) {
    window.rg4js('send', {
      error,
      customData,
    })
  }
}

export function setUser(email: string) {
  if (window.rg4js) {
    window.rg4js('setUser', {
      user: email,
      email,
      isAnonymous: false,
    })
  }
}

export function resetUser() {
  if (window.rg4js) {
    const raygun = window.rg4js('getRaygunInstance')
    if (raygun) {
      raygun.resetAnonymousUser()
    }
  }
}
