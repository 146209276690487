// @flow
import Storage from 'local-storage-fallback'
import decode from 'jwt-decode'
import * as qs from '@src/utils/querystring'

import { AUTH_TOKEN as AUTH_TOKEN_KEY } from '@src/constants/storageKeys'

export function get(): string {
  const urlToken = qs.parse(window.location.search)['tempToken']
  const storageToken = Storage.getItem(AUTH_TOKEN_KEY)

  if (urlToken === undefined) {
    return storageToken
  } else {
    if (storageToken !== urlToken) {
      set(urlToken)
    }
    return urlToken
  }
}

export function set(token: string): void {
  return Storage.setItem(AUTH_TOKEN_KEY, token)
}

export function remove(): void {
  return Storage.removeItem(AUTH_TOKEN_KEY)
}

export function props(prop?: string): Object | string {
  const token = get()

  if (!token) {
    throw new Error(`Cannot read props from a token that does not exist.`)
  }

  const decodedToken: Object = decode(token)

  if (!prop) {
    return decodedToken
  }

  return decodedToken[prop]
}
