// @flow
import React, { Component } from 'react'

import styles from './FullScreenLoader.module.scss'
import Loader from '@src/components/ui/Loader'

export default class FullScreenLoader extends Component<*, *> {
  componentDidMount() {
    this.setNoScrollOnBody()
  }

  componentWillUnmount() {
    this.removeNoScrollFromBody()
  }

  setNoScrollOnBody() {
    window.document.body.classList.add('noScroll')
  }

  removeNoScrollFromBody() {
    window.document.body.classList.remove('noScroll')
  }

  render() {
    return (
      <div className={styles.overlay}>
        <Loader />
      </div>
    )
  }
}
