// @flow
import qs from 'qs'

export function parse(querystring: string = '') {
  const normalize = querystring =>
    querystring[0] === '?' ? querystring.slice(1) : querystring
  const normalizedQuerystring = normalize(querystring)

  return qs.parse(normalizedQuerystring)
}

export function stringify(queryobject: Object = {}, encode: boolean = false) {
  const querystring = qs.stringify(queryobject, {
    encode,
    arrayFormat: 'brackets',
  })

  return `?${querystring}`
}
