// @flow
import {
  createActionType as createActionTypeCreator,
  action as createAction,
  reducer as createReducer,
} from '@src/redux/utils'

type ID = any
type ThemeType = 'success' | 'danger' | 'info' | 'warning'

export type AlertInput = {
  type: ThemeType,
  children: any,
  onClick?: () => void,
}

export type Alert = {
  id: ID,
  type: ThemeType,
  children: any,
  onClick?: () => void,
}

let alertId = 1
const getAlertId = () => alertId++

const createActionType = createActionTypeCreator('Alert')

export const DISPLAY = createActionType('DISPLAY')
export const REMOVE = createActionType('REMOVE')

export const display = (alertInput: AlertInput) => {
  const alert: Alert = Object.assign({}, alertInput, {
    id: getAlertId(),
  })

  return createAction(DISPLAY, alert)
}
export const remove = (id: ID) => createAction(REMOVE, id)

const initialState: Array<Alert> = []

const reducer = createReducer(
  {
    [DISPLAY]: (state, action) => [...state, action.payload],
    [REMOVE]: (state, action) =>
      state.filter((item: Alert) => item.id !== action.payload),
  },
  initialState
)

export default reducer
