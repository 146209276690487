// @flow
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'

import ChargeOrderModal from './ChargeOrderModal'

const withCreditCardsAndBankAccount = graphql(
  gql`
    query CreditCardsAndBankAccount($officeId: ID!) {
      credit_cards(office_id: $officeId) {
        ...ChargeOrderModalCreditCards
      }

      office(id: $officeId) {
        id
        balance
        bank_account {
          account_number
          bank
          taxpayer_id
          recipient
          interbank_account_number
          reference
        }
      }
    }
    ${ChargeOrderModal.fragments.creditCards}
  `
)

const withCreateCreditCard = graphql(
  gql`
    mutation CreateCreditCard(
      $officeId: ID!
      $conektaToken: String
      $stripeToken: String
    ) {
      create_credit_card(
        office_id: $officeId
        conekta_token: $conektaToken
        stripe_token: $stripeToken
      ) {
        ...ChargeOrderModalCreditCards
      }
    }
    ${ChargeOrderModal.fragments.creditCards}
  `,
  {
    name: 'createCreditCardMutation',
    props: ({ createCreditCardMutation }) => ({
      createCreditCard: (
        officeId: string,
        conektaToken?: string,
        stripeToken?: string
      ) =>
        createCreditCardMutation({
          variables: { officeId, conektaToken, stripeToken },
        }),
    }),
  }
)

const withChargeOrder = graphql(
  gql`
    mutation ChargeOrder($orderId: ID!, $creditCardId: ID!) {
      charge_order(order_id: $orderId, credit_card_id: $creditCardId) {
        id
        total_price
      }
    }
  `,
  {
    name: 'chargeOrderMutation',
    props: ({ chargeOrderMutation }) => ({
      chargeOrder: (orderId: string, creditCardId: string) =>
        chargeOrderMutation({
          variables: {
            orderId,
            creditCardId,
          },
        }),
    }),
  }
)

export default compose(
  withCreditCardsAndBankAccount,
  withCreateCreditCard,
  withChargeOrder
)
