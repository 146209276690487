// @flow
import React from 'react'
import cx from 'classnames'

import styles from './CreditCardIcon.module.scss'
import amex from './images/amex.svg'
import discovery from './images/discovery.svg'
import mastercard from './images/mastercard.svg'
import visa from './images/visa.svg'
import generic from './images/generic.svg'
import normalizeCreditCardBrandName from '@src/utils/normalizeCreditCardBrandName'

const cards = { amex, discovery, mastercard, visa }

type CreditCardIconProps = {
  name: string,
  className?: string,
}

function CreditCardIcon(props: CreditCardIconProps) {
  const { name, className } = props
  const normalizedName = normalizeCreditCardBrandName(name)
  const backgroundImage = cards[normalizedName] || generic

  return (
    <span
      className={cx(styles.container, className)}
      style={{ backgroundImage: `url('${backgroundImage}')` }}
    />
  )
}

CreditCardIcon.defaultProps = {
  name: '',
}

export default CreditCardIcon
