// @flow
import { connect } from 'react-redux'

import { open as openModal } from '@src/redux/modules/modal'

const select = state => ({
  currentOfficeId: state.user.preferences.currentOfficeId,
})

const actions = {
  openModal,
}

export default connect(
  select,
  actions
)
