// @flow
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

export default graphql(
  gql`
    query InitialData {
      current_user {
        id
        email
        first_name
        last_name
        permissions
        region {
          id
          slug
          name
          time_zone
          currency
        }
        companies {
          id
          name
          credit_period
        }
        offices {
          id
          name
          company_id
          region {
            id
            slug
            name
            time_zone
            currency
          }
          balance
          monthly_budget
          pay_to_confirm_order
          dashboard_promotion_message
          approve_master_lists
          master_list_approver {
            first_name
            last_name
          }
        }
      }
    }
  `,
  {
    skip: props => !props.userIsLoggedIn,
  }
)
