// @flow
export default function buildAddressString(address: ?Object) {
  const addressComponents = [
    ['street', 'number'],
    'interior_number',
    'colony',
    'city',
    'postal_code',
    'state',
  ]

  return addressComponents
    .map(component => {
      const subComponents = [].concat(component)

      return subComponents
        .map(subComponent => {
          if (!address) {
            return ''
          }
          const value = address[subComponent]

          if (subComponent === 'interior_number') {
            return value && `Int. ${value}`
          }

          if (subComponent === 'postal_code') {
            return value && `C.P. ${value}`
          }

          return value
        })
        .filter(subComponent => subComponent)
        .join(' ')
    })
    .filter(component => component)
    .join(', ')
}
