// @flow
import React, { Component } from 'react'
import compose from 'lodash/flowRight'
import { withRouter } from 'react-router-dom'

import NotEnoughBalanceModal from './NotEnoughBalanceModal'
import redux from './redux'

type NotEnoughBalanceModalContainerProps = {
  targetPrice: number,
  currency: string,
  officeBalance: number,

  // Provided by ModalRoot
  closeModal: () => void,

  error?: string,
  history: *,
}

export class NotEnoughBalanceModalContainer extends Component<
  NotEnoughBalanceModalContainerProps,
  *
> {
  closeModal(): Promise<void> {
    return new Promise((resolve, reject) => {
      resolve(this.props.closeModal())
    })
  }

  goToOrders() {
    this.props.history.push(`/orders`)
  }

  render() {
    const { targetPrice, officeBalance, currency } = this.props

    return (
      <NotEnoughBalanceModal
        targetPrice={`${targetPrice}`}
        currency={currency}
        officeBalance={officeBalance}
      />
    )
  }
}

export default compose(
  redux,
  withRouter
)(NotEnoughBalanceModalContainer)
