// @flow
import React, { Component } from 'react'
import { ApolloProvider } from 'react-apollo'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import configureStore from '@src/redux/store/configureStore'
import graphqlClient from './graphql/client'

import UIRoot from '@src/components/ui-root'
import PageviewRecorder from '@src/components/analytics/PageviewRecorder'

import type { Store } from 'redux'
import type { State, Action } from '@src/redux/types'

type AppState = {
  configuringStore: boolean,
}

class App extends Component<*, AppState> {
  store: Store<State, Action>

  constructor(props: *) {
    super()

    this.state = {
      configuringStore: true,
    }
  }

  componentDidMount() {
    this.setupApplication()
  }

  setupApplication() {
    this.configureStore()
  }

  configureStore() {
    this.store = configureStore()
    this.setState(() => ({ configuringStore: false }))
  }

  isLoading() {
    const tasks = [this.state.configuringStore]

    const loading = task => task === true
    return tasks.some(loading)
  }

  render() {
    if (this.isLoading()) {
      return null
    } else {
      return (
        <ApolloProvider client={graphqlClient}>
          <Provider store={this.store}>
            <Router>
              <div>
                <PageviewRecorder />
                <UIRoot />
              </div>
            </Router>
          </Provider>
        </ApolloProvider>
      )
    }
  }
}

export default App
