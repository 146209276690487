// @flow
import React from 'react'

import type { ChildrenArray, Element as ReactElement } from 'react'

export type SelectProps = {
  children?: ChildrenArray<ReactElement<'option'>>,
  defaultValue?: *,
  value?: *,
}

export default function Select(props: SelectProps): * {
  const { children, ...selectProps } = props

  // I don't know why this hack is needed, the props are losing the 'value' of the RoleChooser Select
  if (props.defaultValue) {
    selectProps.value = props.defaultValue
  }

  const options = children

  return <select {...selectProps}>{options}</select>
}
