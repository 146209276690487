// @flow
import invariant from 'invariant'

import i18n, { I18N } from '@src/lib/i18n'

import type { CreditCard, TokenGenerator } from './types'

export default class ConektaTokenGenerator implements TokenGenerator {
  Conekta: Object

  constructor(publicKey: string) {
    invariant(
      window.Conekta,
      'The Conekta library is not installed. Read more at https://developers.conekta.com/libraries/javascript'
    )

    this.Conekta = window.Conekta

    this.initialize(publicKey)
  }

  initialize(publicKey: string) {
    this.Conekta.setPublicKey(publicKey)

    const locale = i18n.getLocale()
    const language = I18N.getLanguageFromLocale(locale)
    this.Conekta.setLanguage(language)
  }

  generateToken(card: CreditCard): Promise<*> {
    return new Promise(resolve => {
      const onSuccess = (response: Object) => {
        const token = response.id
        resolve({ token })
      }

      const onError = (error: Object) => {
        return resolve({
          error: {
            details: error,
            message: error.message_to_purchaser,
          },
        })
      }

      this.Conekta.token.create({ card }, onSuccess, onError)
    })
  }
}
