// @flow
import React from 'react'
import cx from 'classnames'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'

import styles from './HeaderDropdownMenu.module.scss'
import defaultAvatar from './images/defaultAvatar.png'
import Avatar from '@src/components/ui/Avatar'
import Button from '@src/components/ui/Button'

type Office = {
  id: string,
  name: string,
  balance: number,
  monthly_budget?: ?number,
  company_id: string,
  region: {
    id: string,
  },
}

type HeaderDropdownMenuProps = {
  className?: string,
  isOpen?: boolean,
  onLogout?: () => void,
  onClickOffice?: (office: Office) => void,
  current_user: {
    id: string,
    first_name: string,
    last_name: string,
    email: string,
    offices: Array<Office>,
  },
  currentOfficeId: string,
}

export default function HeaderDropdownMenu(props: HeaderDropdownMenuProps) {
  const {
    className,
    current_user: currentUser,
    currentOfficeId,
    isOpen,
    onLogout = () => {},
    onClickOffice = _ => {},
  } = props

  if (!isOpen) {
    return null
  }

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.currentUser}>
        <Avatar className={styles.avatar} src={defaultAvatar} />

        <div className={styles.userAndActions}>
          <p>
            {currentUser.first_name} {currentUser.last_name}
          </p>
          <p className={styles.userEmail}>{currentUser.email}</p>

          <Button className={styles.logoutButton} link onClick={onLogout}>
            <FormattedMessage
              id="HeaderDropdownMenu.logOut"
              defaultMessage="Cerrar sesión"
              description={`Log out button's text`}
            />
          </Button>
        </div>
      </div>

      <ul className={cx('list-unstyled', styles.offices)}>
        {currentUser.offices
          .map(
            office =>
              office.id !== currentOfficeId && (
                <li key={office.id} onClick={() => onClickOffice(office)}>
                  {office.name}
                </li>
              )
          )
          .filter(x => x)}
      </ul>
    </div>
  )
}

HeaderDropdownMenu.fragments = {
  currentUser: gql`
    fragment HeaderDropdownMenuCurrentUser on User {
      id
      first_name
      last_name
      email
      offices {
        id
        name
      }
    }
  `,
}
