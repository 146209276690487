// @flow
import invariant from 'invariant'

import type { CreditCard, TokenGenerator } from './types'

export default class StripeTokenGenerator implements TokenGenerator {
  Stripe: Object

  constructor(publishableKey: string) {
    invariant(
      window.Stripe,
      'The Stripe library is not installed. Read more at https://stripe.com/docs/stripe-js/reference#including-stripejs.'
    )

    this.Stripe = window.Stripe

    this.initialize(publishableKey)
  }

  initialize(publishableKey: string) {
    this.Stripe.setPublishableKey(publishableKey)
  }

  generateToken(card: CreditCard): Promise<*> {
    return new Promise(resolve => {
      this.Stripe.card.createToken(card, (status, response) => {
        if (response.error) {
          resolve({
            error: {
              details: response.error,
              message: response.error.message,
            },
          })
        }

        const token = response.id
        resolve({ token })
      })
    })
  }
}
