// @flow
import React from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import cx from 'classnames'

import styles from './CreateCreditCardModal.module.scss'
import Button from '@src/components/ui/Button'
import Input from '@src/components/ui/Input'
import CreditCardExpirationDateInput from '@src/components/ui/CreditCardExpirationDateInput'
import CreditCardNumberInput from '@src/components/ui/CreditCardNumberInput'
import FormValidationError from '@src/components/ui/FormValidationError'
import FullScreenLoader from '@src/components/ui/FullScreenLoader'
import { isRequired } from '@src/utils/validation'

import type { MessageDescriptor } from 'react-intl'

const messages = defineMessages({
  creditCardNameLabel: {
    id: 'CreateCreditCardModal.creditCardNameLabel',
    defaultMessage: 'Nombre en la tarjeta',
    description: 'Label asking for customer name',
  },

  creditCardNumberLabel: {
    id: 'CreateCreditCardModal.creditCardNumberLabel',
    defaultMessage: 'Número de tarjeta',
    description: 'Label asking for card number',
  },

  creditCardExpirationDateLabel: {
    id: 'CreateCreditCardModal.creditCardExpirationDateLabel',
    defaultMessage: 'Fecha de vencimiento',
    description: 'Label asking for the card expiration date',
  },

  creditCardCvcLabel: {
    id: 'CreateCreditCardModal.creditCardCvcLabel',
    defaultMessage: 'CVC',
    description: 'Label asking for the card security code',
  },
})

type CreateCreditCardModalProps = {
  onSubmit: () => {},
  submitting: boolean,
  error?: string,

  // Provided by react-intl/injectIntl
  intl: {
    formatMessage: (
      messageDescriptor: MessageDescriptor,
      values?: Object
    ) => string,
  },
}

export function CreateCreditCardModal(props: CreateCreditCardModalProps) {
  const {
    intl: { formatMessage },
    onSubmit,
    submitting,
    error,
  } = props

  return (
    <div className={styles.container}>
      {submitting && <FullScreenLoader />}

      <h1 className={cx('text-title text-center', styles.title)}>
        <FormattedMessage
          id="CreateCreditCardModal.title"
          defaultMessage="Agregar método de pago"
          description="Title"
        />
      </h1>

      <div className={styles.body}>
        <div className="row">
          <div className="col-12">
            <Input
              name="creditCard.name"
              label={formatMessage(messages.creditCardNameLabel)}
              placeholder="John Doe"
              validate={[isRequired]}
            />
          </div>

          <div className="col-12">
            <CreditCardNumberInput
              name="creditCard.number"
              label={formatMessage(messages.creditCardNumberLabel)}
              placeholder="0000 0000 0000 0000"
              validate={[isRequired]}
            />
          </div>

          <div className="col-6">
            <CreditCardExpirationDateInput
              name="creditCard.expirationDate"
              label={formatMessage(messages.creditCardExpirationDateLabel)}
              validate={[isRequired]}
            />
          </div>

          <div className="col-6">
            <Input
              name="creditCard.cvc"
              label={formatMessage(messages.creditCardCvcLabel)}
              validate={[isRequired]}
            />
          </div>
        </div>

        {error && <FormValidationError error={error} />}

        <div className={styles.buttons}>
          <Button
            className={styles.submitButton}
            onClick={onSubmit}
            disabled={submitting}
          >
            {submitting ? (
              <FormattedMessage
                id="CreateCreditCardModal.submitButtonWhileSubmitting"
                defaultMessage="AGREGANDO..."
                description="Submit button while submitting"
              />
            ) : (
              <FormattedMessage
                id="CreateCreditCardModal.submitButton"
                defaultMessage="AGREGAR"
                description="Submit button"
              />
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(CreateCreditCardModal)
