// @flow
import { combineReducers } from 'redux'

import {
  createActionType as createActionTypeCreator,
  action as createAction,
  reducer as createReducer,
} from '@src/redux/utils'
import preferences from './preferences'
import region from './region'
import * as authToken from '@src/utils/authToken'

const createActionType = createActionTypeCreator('User')

export const LOG_IN = createActionType('LOG_IN')
export const LOG_OUT = createActionType('LOG_OUT')

export const logIn = (token: string) => {
  authToken.set(token)
  return createAction(LOG_IN)
}

export const logOut = () => {
  authToken.remove()
  return createAction(LOG_OUT)
}

export const isLoggedIn = createReducer(
  {
    [LOG_IN]: () => true,
    [LOG_OUT]: () => false,
  },
  !!authToken.get()
)

export default combineReducers({
  isLoggedIn,
  preferences,
  region,
})
