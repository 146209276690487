// @flow
import React, { Component } from 'react'
import compose from 'lodash/flowRight'
import { reduxForm } from 'redux-form'

import RequestMasterListModal from './RequestMasterListModal'
import graphql from './graphql'

import { handleUnexpectedError } from '@src/lib/payOrder'

type FormData = {
  emails: Array<string>,
}

type RequestMasterListModalContainerProps = {
  office: Object,
  closeModal: () => void,
  requestMasterListApprovalMutation: (
    id: string
  ) => Promise<{
    data: {
      request_master_list_approval: {
        id: string,
        waiting_for_approval: boolean,
      },
    },
  }>,

  // Redux form props
  handleSubmit: Function,
  submitting: boolean,
  masterListId: string,
  openModal: ({ type: string, data?: Object }) => void,
}

export class RequestMasterListModalContainer extends Component<
  RequestMasterListModalContainerProps,
  *
> {
  handleSubmit: (formData: FormData) => Promise<void>

  constructor(props: RequestMasterListModalContainerProps) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = { error: '' }
  }

  async handleSubmit(formData: FormData) {
    const { requestMasterListApprovalMutation, masterListId } = this.props

    return requestMasterListApprovalMutation(masterListId)
      .then(result => {
        this.props.closeModal()
      })
      .catch(error => {
        this.props.closeModal()

        handleUnexpectedError(error, this.props.openModal)
      })
  }

  render() {
    const { handleSubmit, submitting, office } = this.props

    if (!office) {
      return false
    }

    return (
      <RequestMasterListModal
        onSubmit={handleSubmit(this.handleSubmit)}
        requesting={submitting}
        approver={office.master_list_approver}
      />
    )
  }
}

export default compose(
  graphql,
  reduxForm({
    form: 'RequestMasterListModal',
  })
)(RequestMasterListModalContainer)
