// @flow
import React, { Component } from 'react'
import compose from 'lodash/flowRight'
import { reduxForm, SubmissionError } from 'redux-form'

import NewOfficeModal from './NewOfficeModal'
import * as errorReporter from '@src/lib/errorReporter'
import graphql from './graphql'
import convertGeocoderResultToAddress from '@src/utils/convertGeocoderResultToAddress'

import type { OfficeInput } from './graphql'

type FormData = {
  name: string,
  address: Object,
  phone: string,
  square_meters: string,
  max_occupancy: string,
  occupancy: string,
}

type NewOfficeModalContainerProps = {
  closeModal: () => void,
  companyId: string,

  // Provided by graphql
  createNewOffice: (
    companyId: string,
    officeInput: Object
  ) => Promise<{
    data: {
      create_office: Object,
    },
  }>,

  // Provided by redux-form
  handleSubmit: Function,
  submitting: boolean,
  error?: string,
}

export class NewOfficeModalContainer extends Component<
  NewOfficeModalContainerProps,
  *
> {
  handleSubmit: (formData: FormData) => Promise<void>

  constructor(props: NewOfficeModalContainerProps) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async handleSubmit(formData: FormData) {
    const officeInput = this.getOfficeInputFromFormData(formData)

    const createNewOfficeResponse = await this.createNewOffice(
      this.props.companyId,
      officeInput
    )
    this.onSubmitSuccess(
      createNewOfficeResponse && createNewOfficeResponse.data.create_office
    )
  }

  getOfficeInputFromFormData(formData: FormData): OfficeInput {
    return {
      name: formData.name,
      address: this.getAddress(formData.address),
      phone: formData.phone,
      square_meters: parseInt(formData.square_meters, 10),
      occupancy: parseInt(formData.occupancy, 10),
      max_occupancy: parseInt(formData.max_occupancy, 10),
    }
  }

  getAddress(formDataAddress: Object): Object {
    const address = convertGeocoderResultToAddress(formDataAddress.gmaps)

    return {
      street: address.street,
      number: address.number,
      state: address.state,
      city: address.city,
      postal_code: address.postal_code,
      latitude: address.latitude,
      longitude: address.longitude,
    }
  }

  async createNewOffice(companyId: string, officeInput: OfficeInput) {
    try {
      return await this.props.createNewOffice(companyId, officeInput)
    } catch (e) {
      errorReporter.log(e)
      this.throwGenericError()
    }
  }

  throwGenericError() {
    throw new SubmissionError({
      _error:
        'Hubo un error guardando la ubicación. Por favor vuelve a intentar.',
    })
  }

  onSubmitSuccess(newOffice?: Object) {
    if (newOffice) {
      window.location.assign(`/offices/${newOffice.id}`)
    } else {
      window.location.reload()
    }
  }

  render() {
    return (
      <NewOfficeModal
        onSubmit={this.props.handleSubmit(this.handleSubmit)}
        submitting={this.props.submitting}
        error={this.props.error}
      />
    )
  }
}

export default compose(
  graphql,
  reduxForm({
    form: 'NewOfficeModal',
  })
)(NewOfficeModalContainer)
