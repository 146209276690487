// @flow
import React from 'react'
import cx from 'classnames'
import compose from 'lodash/flowRight'
import { reduxForm } from 'redux-form'
import { injectIntl, FormattedMessage } from 'react-intl'

import styles from './DeliveryInformationModal.module.scss'
import messages from './messages'
import Button from '@src/components/ui/Button'
import Input from '@src/components/ui/Input'
import FormValidationError from '@src/components/ui/FormValidationError'
import { isRequired } from '@src/utils/validation'

import Tabs from '@src/components/ui/Tabs'

type DeliveryInformationModalProps = {
  submitting: boolean,
  handleSubmit: () => void,
  intl: *,
  error?: any,
}

export function DeliveryInformationModal(props: DeliveryInformationModalProps) {
  const {
    intl: { formatMessage },
    error,
    handleSubmit,
    submitting,
  } = props

  return (
    <div className={styles.container}>
      <h1 className="text-title">
        <FormattedMessage
          id="DeliveryInformationModal.title"
          defaultMessage="Instrucciones de entrega"
          description="Title"
        />
      </h1>

      <form className={styles.body}>
        <div className={cx('row', styles.deliveryTimeWindowSection)}>
          <p>{formatMessage(messages.deliveryTimeWindow)}</p>
          <div className="col-12">
            <Input name="delivery_time_window" validate={[isRequired]} />
          </div>
        </div>

        <div className={cx('row')}>
          <p>{formatMessage(messages.deliveryComments)}</p>
          <div className="col-12">
            <Input
              name="delivery_comments"
              placeholder="No entregar en camión de mas de 3 mts de altura"
            />
          </div>
        </div>

        <Tabs className={styles.contactsTabs}>
          <div label="Contacto para entrega">
            <Input
              name="first_delivery_contact_name"
              label={formatMessage(messages.nameLabel)}
              validate={[isRequired]}
              maxLength="72"
            />

            <Input
              name="first_delivery_contact_phone"
              label={formatMessage(messages.phoneLabel)}
              validate={[isRequired]}
              maxLength="72"
            />
          </div>
          <div label="Contacto alternativo">
            <Input
              name="second_delivery_contact_name"
              label={formatMessage(messages.nameLabel)}
              maxLength="72"
            />

            <Input
              name="second_delivery_contact_phone"
              label={formatMessage(messages.phoneLabel)}
              maxLength="72"
            />
          </div>
        </Tabs>

        {error && <FormValidationError error={error} />}

        <div className={cx(styles.section, styles.actionButtons)}>
          <Button
            className={styles.submitButton}
            onClick={handleSubmit}
            disabled={submitting}
          >
            {submitting ? (
              <FormattedMessage
                id="DeliveryInformationModal.submitButtonWhileSubmitting"
                defaultMessage="Guardando los datos..."
                description="Submit button text while updating the master list"
              />
            ) : (
              <FormattedMessage
                id="DeliveryInformationModal.submitButton"
                defaultMessage="Actualizar datos de entrega"
                description="Submit button text to update a master list"
              />
            )}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default compose(
  reduxForm({
    form: 'DeliveryInformationForm',
  }),
  injectIntl
)(DeliveryInformationModal)
