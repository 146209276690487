// @flow
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'

const withInviteUser = graphql(
  gql`
    mutation inviteUserToOffice(
      $officeId: ID!
      $email: String!
      $role: String!
    ) {
      invite_user(office_id: $officeId, email: $email, role: $role) {
        ... on UserInvitation {
          id
        }

        ... on User {
          id
        }
      }
    }
  `,
  {
    name: 'inviteUser',
    props: ({ inviteUser }) => ({
      inviteUser: (officeId: string, email: string, role: string) =>
        inviteUser({
          variables: { officeId, email, role },
        }),
    }),
  }
)

export default compose(withInviteUser)
