// @flow
import React from 'react'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'

import Image from '@src/components/ui/Image'

import image from './images/500.svg'
import styles from './Error500.module.scss'

const Error500 = () => (
  <div className={styles.container}>
    <Image className={styles.image} src={image} />

    <h1 className={styles.title}>500</h1>

    <h2 className={cx('text-title', styles.subtitle)}>
      <FormattedMessage
        id="Error500.subtitle"
        defaultMessage="¡Ooops! algo no ha salido bien…"
        description="The 500 subtitle"
      />
    </h2>

    <h3 className={cx('text-headline text-secondary', styles.description)}>
      <FormattedMessage
        id="Error500.description"
        defaultMessage="Ha ocurrido un error con la página que intentas visitar."
        description="The 500 description"
      />
    </h3>
  </div>
)

export default Error500
