// @flow
import React from 'react'
import cx from 'classnames'

import styles from './Loader.module.scss'

type LoaderProps = {
  className?: string,
}

export default function Loader(props: LoaderProps) {
  return (
    <div className={cx(styles.loaderContainer, props.className)}>
      <svg className={styles.loader} viewBox="25 25 50 50">
        <circle className={styles.path} cx="50" cy="50" r="20" fill="none" />
      </svg>
    </div>
  )
}
