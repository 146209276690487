// @flow
import React from 'react'
import compose from 'lodash/flowRight'

import NextEventsWidgetScreen from './NextEventsWidgetScreen'

import graphql, { type Orders } from './graphql'

type NextEventsWidgetContainerProps = {
  currentOfficeId: string,
  data: {
    orders?: Orders,
    loading: boolean,
  },
}

function NextEventsWidgetContainer(props: NextEventsWidgetContainerProps) {
  const {
    data: { orders, loading },
  } = props

  return <NextEventsWidgetScreen orders={orders} loading={loading} />
}

export default compose(graphql)(NextEventsWidgetContainer)
