// @flow
import React, { Component } from 'react'
import cx from 'classnames'

import styles from './BankTransferModal.module.scss'
import illustration from './images/party.svg'
import Image from '@src/components/ui/Image'
import Button from '@src/components/ui/Button'

type BankTransferModalProps = {
  customerName: string,
  closeModal: () => void,
  onConfirm: () => Promise<void>,
}

export default class BankTransferModal extends Component<
  BankTransferModalProps,
  *
> {
  handleConfirm: () => Promise<void>

  constructor(props: BankTransferModalProps) {
    super(props)

    this.handleConfirm = this.handleConfirm.bind(this)

    this.state = {
      isConfirmButtonEnabled: true,
    }
  }

  async handleConfirm() {
    this.disableConfirmButton()
    await this.props.onConfirm()
    this.enableConfirmButton()
  }

  disableConfirmButton() {
    this.setState(() => ({ isConfirmButtonEnabled: false }))
  }

  enableConfirmButton() {
    this.setState(() => ({ isConfirmButtonEnabled: true }))
  }

  render() {
    const { customerName, closeModal } = this.props

    const { isConfirmButtonEnabled } = this.state

    return (
      <div className={cx(styles.container)}>
        <Image src={illustration} className={styles.section} />

        <h1 className={cx('text-title', styles.section)}>
          ¡Muchas gracias por tu orden {customerName}!
        </h1>

        <div className={cx(styles.section)}>
          <p>
            Al confirmar tu orden, te estará llegando un correo con nuestra
            información bancaria.
          </p>

          <p>
            En Tandem queremos brindarte una solución de administración cada vez
            más fácil para que te enfoques sólo en crecer tu negocio.
          </p>
        </div>

        <div className={cx(styles.section, styles.actionButtons)}>
          {isConfirmButtonEnabled && (
            <Button
              className={cx('text-dark', styles.cancelButton)}
              link
              onClick={closeModal}
            >
              Cancelar orden
            </Button>
          )}

          <Button
            className={styles.confirmButton}
            primary
            onClick={this.handleConfirm}
            disabled={!isConfirmButtonEnabled}
          >
            {isConfirmButtonEnabled
              ? 'Confirmar orden'
              : 'Confirmando la orden...'}
          </Button>
        </div>
      </div>
    )
  }
}
