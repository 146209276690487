// @flow
import React from 'react'
import cx from 'classnames'

type FormValidationErrorProps = {
  className?: string,
  error?: string,
}

export default function FormValidationError({
  error,
  className,
}: FormValidationErrorProps) {
  return <p className={cx('text-danger', className)}>{error}</p>
}
