// @flow
import React, { Component } from 'react'
import cx from 'classnames'

import Image from '@src/components/ui/Image'
import styles from './Avatar.module.scss'

type AvatarProps = {
  className?: string,
  src?: string,
  title?: string,
  small?: boolean,
  large?: boolean,
}

export default class Avatar extends Component<AvatarProps, *> {
  render() {
    const { className, src, title, small, large } = this.props

    const finalClassName = cx(className, {
      [styles.default]: !small && !large,
      [styles.small]: small,
      [styles.large]: large,
    })

    if (src) {
      return <Image src={src} className={finalClassName} circle />
    } else {
      return <div className={cx(finalClassName, styles.container)}>{title}</div>
    }
  }
}
