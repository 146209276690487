// @flow
import React from 'react'
import cx from 'classnames'

import styles from './CreditCardIconsList.module.scss'
import CreditCardIcon from '@src/components/ui/CreditCardIcon'

export default function CreditCardIconsList() {
  return (
    <ul className={cx('list-inline', styles.list)}>
      <li className={cx('list-inline-item', styles.listItem)}>
        <CreditCardIcon />
      </li>

      <li className={cx('list-inline-item', styles.listItem)}>
        <CreditCardIcon name="visa" />
      </li>

      <li className={cx('list-inline-item', styles.listItem)}>
        <CreditCardIcon name="mastercard" />
      </li>

      <li className={cx('list-inline-item', styles.listItem)}>
        <CreditCardIcon name="amex" />
      </li>
    </ul>
  )
}
