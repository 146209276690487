// @flow
export default function normalizeCreditCardBrandName(name: string) {
  switch (name) {
    case 'VISA':
    case 'Visa':
      return 'visa'

    case 'MasterCard':
    case 'MC':
      return 'mastercard'

    case 'American Express':
    case 'AMERICAN_EXPRESS':
      return 'amex'

    default:
      return name.toLowerCase()
  }
}
