// @flow
import Loadable from 'react-loadable'

import Loader from '@src/components/ui/ReactLoadableLoader'

const LoginLoader = Loadable({
  loader: () => import('./LoginContainer'),
  loading: Loader,
})

export default LoginLoader
