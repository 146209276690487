import React from 'react'
import Loader from '@src/components/ui/Loader'

import styles from './UIRoot.module.scss'

function Loading() {
  return (
    <div className={styles.loading}>
      <Loader />
    </div>
  )
}

export default Loading
