// @flow
import React from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import cx from 'classnames'

import styles from './NewOfficeModal.module.scss'
import Button from '@src/components/ui/Button'
import Input from '@src/components/ui/Input'
import GeoSearchControl from '@src/components/ui/GeoSearchControl'
import FormValidationError from '@src/components/ui/FormValidationError'
import FullScreenLoader from '@src/components/ui/FullScreenLoader'
import { isRequired, isValidAddress } from '@src/utils/validation'

import type { MessageDescriptor } from 'react-intl'

const messages = defineMessages({
  nameLabel: {
    id: 'NewOfficeModal.nameLabel',
    defaultMessage: 'Nombre de la ubicación',
    description: 'Name field label',
  },

  addressLabel: {
    id: 'NewOfficeModal.addressLabel',
    defaultMessage: 'Dónde están ubicados',
    description: 'Address field label',
  },

  phoneLabel: {
    id: 'NewOfficeModal.phoneLabel',
    defaultMessage: 'Teléfono de contacto para entregas',
    description: 'Phone field abel',
  },

  squareMetersLabel: {
    id: 'NewOfficeModal.squareMetersLabel',
    defaultMessage: 'Metros cuadrados de la ubicación',
    description: 'Office space area field label',
  },

  maxOccupancyLabel: {
    id: 'NewOfficeModal.maxOccupancyLabel',
    defaultMessage: 'Capacidad máxima de ocupación de la ubicación',
    description: 'Maximum office occupancy field label',
  },

  occupancyLabel: {
    id: 'NewOfficeModal.occupancyLabel',
    defaultMessage: 'Número de personas que usualmente trabajan ahí',
    description: 'Current office occupancy field label',
  },
})

type NewOfficeModalProps = {
  onSubmit: () => {},
  submitting: boolean,
  error?: string,

  // Provided by react-intl/injectIntl
  intl: {
    formatMessage: (
      messageDescriptor: MessageDescriptor,
      values?: Object
    ) => string,
  },
}

export function NewOfficeModal(props: NewOfficeModalProps) {
  const {
    intl: { formatMessage },
    onSubmit,
    submitting,
    error,
  } = props

  return (
    <div className={styles.container}>
      {submitting && <FullScreenLoader />}

      <h1 className={cx('text-title text-center', styles.title)}>
        <FormattedMessage
          id="NewOfficeModal.title"
          defaultMessage="Agregar ubicación"
          description="Modal title"
        />
      </h1>

      <div className={styles.body}>
        <div className="row">
          <div className="col-12">
            <Input
              name="name"
              label={formatMessage(messages.nameLabel)}
              validate={[isRequired]}
            />
          </div>

          <div className="col-12">
            <GeoSearchControl
              name="address"
              placeholder=" "
              label={formatMessage(messages.addressLabel)}
              validate={[isRequired, isValidAddress]}
            />
          </div>

          <div className="col-6">
            <Input
              type="tel"
              name="phone"
              label={formatMessage(messages.phoneLabel)}
              validate={[isRequired]}
            />
          </div>

          <div className="col-6">
            <Input
              type="number"
              name="square_meters"
              label={formatMessage(messages.squareMetersLabel)}
            />
          </div>

          <div className="col-6">
            <Input
              type="number"
              name="max_occupancy"
              label={formatMessage(messages.maxOccupancyLabel)}
            />
          </div>

          <div className="col-6">
            <Input
              type="number"
              name="occupancy"
              label={formatMessage(messages.occupancyLabel)}
            />
          </div>
        </div>

        {error && <FormValidationError error={error} />}

        <div className={styles.buttons}>
          <Button
            cta
            className={styles.submitButton}
            onClick={onSubmit}
            disabled={submitting}
          >
            {submitting ? (
              <FormattedMessage
                id="NewOfficeModal.submitButtonWhileSubmitting"
                defaultMessage="AGREGANDO..."
                description="Submit button while submitting"
              />
            ) : (
              <FormattedMessage
                id="NewOfficeModal.submitButton"
                defaultMessage="AGREGAR"
                description="Submit button"
              />
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(NewOfficeModal)
