// @flow
import React, { Component } from 'react'
import moment from 'moment-timezone'
import omit from 'lodash/omit'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { SingleDatePicker as VendorSingleDatePicker } from 'react-dates'
import cx from 'classnames'

import './SingleDatePicker.module.scss'
import Icon from '@src/components/ui/Icon'
type SingleDatePickerProps = {
  value: any,
  onChange: (value: any) => void,
  onFocus: () => void,
  onBlur: (value: any) => void,
  meta: {
    active: boolean,
    error: string,
  },
}

export default class SingleDatePicker extends Component<
  SingleDatePickerProps,
  *
> {
  static defaultProps: Object
  timer: *

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  handleFocusChange(focused: boolean) {
    if (focused) {
      this.props.onFocus()
    } else {
      // The hardcoded delay allows for the onChange to take effect. Otherwise, onBlur will
      // be called too fast and it may be passed an undefined value.
      this.timer = setTimeout(() => this.props.onBlur(this.props.value), 100)
    }
  }

  render() {
    const rest = omit(this.props, [
      'value',
      'onChange',
      'meta',
      'onFocus',
      'onBlur',
      'className',
      'aria-describedby',
      'type',
      'name',
    ])

    const { value } = this.props

    const date = moment.isMoment(value) ? value : moment(value)

    const className = cx({
      'is-invalid': this.props.meta.error,
    })

    return (
      <div className={className}>
        <VendorSingleDatePicker
          date={date}
          onDateChange={this.props.onChange}
          focused={this.props.meta.active}
          onFocusChange={({ focused }) => this.handleFocusChange(focused)}
          navPrev={<Icon name="chevron-circle-left" />}
          navNext={<Icon name="chevron-circle-right" />}
          {...rest}
        />
      </div>
    )
  }
}

SingleDatePicker.defaultProps = {
  numberOfMonths: 1,
  hideKeyboardShortcutsPanel: true,
}
