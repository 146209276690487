// @flow
import React from 'react'
import cx from 'classnames'

import styles from './ReactLoadableLoader.module.scss'

type ReactLoadableLoaderProps = {
  isLoading: boolean,
  pastDelay: boolean,
  error: boolean,
}

function ReactLoadableLoader(props: ReactLoadableLoaderProps) {
  const { isLoading, pastDelay, error } = props

  if (isLoading && pastDelay) {
    return (
      <div className={cx('text-dark mt-5', styles.loader)}>Cargando...</div>
    )
  } else if (error) {
    return (
      <div className={cx('text-danger mt-5', styles.loader)}>
        Hubo un error cargando la página
      </div>
    )
  }

  return null
}

export default ReactLoadableLoader
