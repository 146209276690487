// @flow
import React, { Component } from 'react'

import type { Node as ReactNode } from 'react'

import ConfirmActionModal from './ConfirmActionModal'
import * as errorReporter from '@src/lib/errorReporter'

type ConfirmActionModalContainerProps = {
  title: ReactNode,
  description: ReactNode,
  closeModal: () => void,
  onConfirm: () => any,
  afterCancel?: () => void,
}

export default class ConfirmActionModalContainer extends Component<
  ConfirmActionModalContainerProps,
  *
> {
  handleCancel: () => void
  handleConfirm: () => Promise<void>

  constructor(props: ConfirmActionModalContainerProps) {
    super(props)

    this.handleCancel = this.handleCancel.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)

    this.state = {
      error: undefined,
      confirming: false,
    }
  }

  clearError() {
    this.setState(() => ({ error: undefined }))
  }

  setError() {
    this.setState(() => ({
      error: 'Hubo un error. Por favor vuelve a intentar.',
    }))
  }

  setConfirming(confirming: boolean) {
    this.setState(() => ({ confirming }))
  }

  handleCancel() {
    this.props.closeModal()
    if (typeof this.props.afterCancel === 'function') {
      this.props.afterCancel()
    }
  }

  async handleConfirm() {
    this.clearError()
    this.setConfirming(true)

    try {
      await this.props.onConfirm()
      this.setConfirming(false)
    } catch (e) {
      this.setConfirming(false)
      errorReporter.log(e)
      return this.setError()
    }

    this.props.closeModal()
  }

  render() {
    return (
      <ConfirmActionModal
        title={this.props.title}
        description={this.props.description}
        error={this.state.error}
        onCancel={this.handleCancel}
        onConfirm={this.handleConfirm}
        confirming={this.state.confirming}
      />
    )
  }
}
