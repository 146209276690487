// @flow
import React from 'react'
import { FormattedDate as OriginalFormattedDate } from 'react-intl'

import i18n from '@src/lib/i18n'

export default function FormattedDate(props: *) {
  const timeZone = i18n.getTimeZone()

  return <OriginalFormattedDate timeZone={timeZone} {...props} />
}
