// @flow
import React from 'react'

import createFormGroup from './createFormGroup'

import type { ComponentType } from 'react'
import type { FieldProps } from 'redux-form'

import type { FormGroupProps } from '@src/components/ui/FormGroup/FormGroup'

type FormGroupFieldAdapterProps = FieldProps & FormGroupProps

export default function createFormGroupFieldAdapter(
  WrappedComponent: ComponentType<*>
): * {
  const WrappedComponentFormGroup = createFormGroup(WrappedComponent)

  function ComponentFormGroupFieldAdapter(
    props: FormGroupFieldAdapterProps
  ): * {
    const { meta, input, ...otherInputAttributes } = props

    return (
      <WrappedComponentFormGroup
        {...input}
        meta={meta}
        {...otherInputAttributes}
      />
    )
  }

  const wrappedComponentName =
    (WrappedComponent instanceof React.Component &&
      WrappedComponent.displayName) ||
    WrappedComponent.name ||
    'Component'

  ComponentFormGroupFieldAdapter.displayName = `FormGroupFieldAdapter(${wrappedComponentName})`

  return ComponentFormGroupFieldAdapter
}
