// @flow
import React from 'react'
import compose from 'lodash/flowRight'

import BalanceWidgetScreen from './BalanceWidgetScreen'

import graphql, { type Office, type Orders } from './graphql'

type BalanceWidgetContainerProps = {
  data: {
    office?: Office,
    orders?: Orders,
    loading: boolean,
  },
  currency: string,
}

function BalanceWidgetContainer(props: BalanceWidgetContainerProps) {
  const {
    data: { office, orders, loading },
    currency,
  } = props

  return (
    <BalanceWidgetScreen
      currency={currency}
      office={office}
      orders={orders}
      loading={loading}
    />
  )
}

export default compose(graphql)(BalanceWidgetContainer)
