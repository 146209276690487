// @flow
import React from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import NavBarSwitch from '@src/components/react-router/NavBarSwitch'
import Redirect from '@src/components/react-router/Redirect'
import AuthRoute from '@src/components/react-router/AuthRoute'
import ExpensesReports from './ExpensesReports'

const messages = defineMessages({
  generalExpenses: {
    id: 'ReportsScreen.generalExpenses',
    defaultMessage: 'Gastos Generales',
    description: 'General expenses report',
  },
})

type ReportsScreenProps = {
  intl: *,
}

export function ReportsScreen(props: ReportsScreenProps) {
  return (
    <div>
      <p>
        <FormattedMessage
          id="ReportsScreen.title"
          defaultMessage="Mis reportes"
          description="Title of the reports screen"
        />
      </p>

      <NavBarSwitch>
        <AuthRoute
          name={props.intl.formatMessage(messages.generalExpenses)}
          exact
          path="/reports/expenses"
          component={ExpensesReports}
        />

        <Redirect to="/reports/expenses" />
      </NavBarSwitch>
    </div>
  )
}

export default injectIntl(ReportsScreen)
