// @flow
import { createStore, applyMiddleware, compose } from 'redux'

import rootReducer from '@src/redux/modules/rootReducer'

function configureStore() {
  const initialState = {}
  const enhancers = []
  const middlewares = []

  const { devToolsExtension } = window
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }

  const composedEnhancers = compose(
    applyMiddleware(...middlewares),
    ...enhancers
  )

  const store = createStore(rootReducer, initialState, composedEnhancers)

  return store
}

export default configureStore
