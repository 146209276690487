// @flow
import React from 'react'
import cx from 'classnames'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import styles from './ChargeOrderModal.module.scss'
import Icon from '@src/components/ui/Icon'
import Input from '@src/components/ui/Input'
import CreditCardIconsList from './CreditCardIconsList'
import CreditCardExpirationDateInput from '@src/components/ui/CreditCardExpirationDateInput'
import CreditCardNumberInput from '@src/components/ui/CreditCardNumberInput'

import type { MessageDescriptor } from 'react-intl'
import type { PaymentMethod } from './types'

const messages = defineMessages({
  creditCardNameLabel: {
    id: 'ChargeOrderModal.creditCardNameLabel',
    defaultMessage: 'Nombre en la tarjeta',
    description: 'Label asking for customer name',
  },

  creditCardNumberLabel: {
    id: 'ChargeOrderModal.creditCardNumberLabel',
    defaultMessage: 'Número de tarjeta',
    description: 'Label asking for card number',
  },

  creditCardExpirationDateLabel: {
    id: 'ChargeOrderModal.creditCardExpirationDateLabel',
    defaultMessage: 'Fecha de vencimiento',
    description: 'Label asking for the card expiration date',
  },

  creditCardCvcLabel: {
    id: 'ChargeOrderModal.creditCardCvcLabel',
    defaultMessage: 'CVC',
    description: 'Label asking for the card security code',
  },
})

type NewCreditCardPaymentMethodProps = {
  onSelect: (paymentMethod: PaymentMethod) => void,
  selected: boolean,
  creditCardsCount: number,
  intl: {
    formatMessage: (
      messageDescriptor: MessageDescriptor,
      values?: Object
    ) => string,
  },
}

export const NEW_CREDIT_CARD = 'NEW_CREDIT_CARD'

export function NewCreditCardPaymentMethod(
  props: NewCreditCardPaymentMethodProps
) {
  const {
    onSelect,
    selected,
    creditCardsCount,
    intl: { formatMessage },
  } = props

  return (
    <div
      className={cx(styles.option, {
        [styles.optionSelected]: selected,
      })}
    >
      <div
        className={styles.optionHeader}
        onClick={() =>
          onSelect({
            type: NEW_CREDIT_CARD,
          })
        }
      >
        <Icon name="check" className={styles.selectedIcon} />

        <div className="text-small-body-2">
          {creditCardsCount === 0 ? (
            <div>
              <div
                className={cx('text-small-body-2', styles.paymentMethodTitle)}
              >
                <FormattedMessage
                  id="NewCreditCardPaymentMethod.addFirstCardTitle"
                  defaultMessage="Tarjeta de crédito"
                  description="When the user has not registered any credit card, this is the title of the payment method"
                />
              </div>

              <div className="d-flex flex-row justify-content-between">
                <p
                  className={cx(
                    'text-small-body',
                    styles.paymentMethodDescription
                  )}
                >
                  <FormattedMessage
                    id="NewCreditCardPaymentMethod.addFirstCardDescription"
                    defaultMessage="Puedes pagar con tu tarjeta de crédito o debito."
                    description="When the user has not registered any credit card, this is the description of the payment method"
                  />
                </p>

                <CreditCardIconsList />
              </div>
            </div>
          ) : (
            <FormattedMessage
              id="NewCreditCardPaymentMethod.addCardTitle"
              defaultMessage="Agrega otra tarjeta"
              description="When the user has registered credit cards, this is the title of the payment method"
            />
          )}
        </div>
      </div>

      {selected && (
        <div className={styles.optionBody}>
          <div className="row">
            <div className="col-12">
              <Input
                name="creditCard.name"
                label={formatMessage(messages.creditCardNameLabel)}
                placeholder="John Doe"
              />
            </div>

            <div className="col-12">
              <CreditCardNumberInput
                name="creditCard.number"
                label={formatMessage(messages.creditCardNumberLabel)}
                placeholder="0000 0000 0000 0000"
              />
            </div>

            <div className="col-6">
              <CreditCardExpirationDateInput
                name="creditCard.expirationDate"
                label={formatMessage(messages.creditCardExpirationDateLabel)}
              />
            </div>

            <div className="col-6">
              <Input
                name="creditCard.cvc"
                label={formatMessage(messages.creditCardCvcLabel)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default injectIntl(NewCreditCardPaymentMethod)
