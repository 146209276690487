// @flow
import { connect } from 'react-redux'

import { setCurrentOffice } from '@src/redux/modules/user/preferences'
import { getCurrentRegion } from '@src/redux/selectors'

const select = state => ({
  isUserLoggedIn: state.user.isLoggedIn,
  currency: getCurrentRegion(state) && getCurrentRegion(state).currency,
})

const actions = {
  setCurrentOffice,
}

export default connect(
  select,
  actions
)
