// @flow
import { defineMessages } from 'react-intl'

export default defineMessages({
  nameLabel: {
    id: 'UpdateMasterListModal.nameLabel',
    defaultMessage: 'Nombre de la lista',
    description: 'Label for the form field asking the name of the list',
  },
})
