// @flow
import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import compose from 'lodash/flowRight'

import type { ContextRouter } from 'react-router-dom'

type PageviewRecorderProps = ContextRouter & {}

type PageviewFields = {
  location?: string,
  page?: string,
}

export class PageviewRecorder extends Component<PageviewRecorderProps, *> {
  handleLocationChange: (location: *, action: *) => void
  unlisten: void | (() => void)

  constructor(props: PageviewRecorderProps) {
    super(props)

    this.handleLocationChange = this.handleLocationChange.bind(this)
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(this.handleLocationChange)
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten()
    }
  }

  handleLocationChange(location: *, action: *) {
    const { pathname } = location
    const normalizedPathname = this.normalizePathname(pathname)

    this.sendPageview({
      location: window.location.href,
      page: normalizedPathname,
    })
  }

  normalizePathname(pathname: string) {
    function replaceIdsInPathname(pathname: string): string {
      const idRegex = /\/\d+\/?/g
      return pathname.replace(idRegex, '/:id/')
    }

    function removeFinalSlash(pathname: string): string {
      const finalForwardSlashRegex = /\/+$/
      return pathname.replace(finalForwardSlashRegex, '')
    }

    return compose(
      removeFinalSlash,
      replaceIdsInPathname
    )(pathname)
  }

  sendPageview(pageviewFields: PageviewFields) {
    if (window.ga) {
      window.ga('set', 'location', pageviewFields.location)
      window.ga('set', 'page', pageviewFields.page)
      window.ga('send', 'pageview')
    }
  }

  render() {
    return null
  }
}

export default withRouter(PageviewRecorder)
