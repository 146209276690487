// @flow
import React from 'react'
import { Field } from 'redux-form'

import createFormGroupFieldAdapter from './createFormGroupFieldAdapter'

import type { ComponentType } from 'react'

export default function createFormGroupField(
  WrappedComponent: ComponentType<*>
): * {
  const WrappedComponentFormGroupFieldAdapter = createFormGroupFieldAdapter(
    WrappedComponent
  )

  function ComponentFormGroupField(props: *): * {
    return (
      <Field {...props} component={WrappedComponentFormGroupFieldAdapter} />
    )
  }

  const wrappedComponentName =
    (WrappedComponent instanceof React.Component &&
      WrappedComponent.displayName) ||
    WrappedComponent.name ||
    'Component'

  ComponentFormGroupField.displayName = `FormGroupField(${wrappedComponentName})`

  return ComponentFormGroupField
}
