// @flow
import Loadable from 'react-loadable'

import Loader from '@src/components/ui/ReactLoadableLoader'

const MasterListsLoader = Loadable({
  loader: () => import('./MasterListsContainer'),
  loading: Loader,
})

export default MasterListsLoader
