// @flow
import Loadable from 'react-loadable'

import Loader from '@src/components/ui/ReactLoadableLoader'

const RegisterLoader = Loadable({
  loader: () => import('./RegisterContainer'),
  loading: Loader,
})

export default RegisterLoader
