// @flow
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import alert from './alert'
import modal from './modal'
import user from './user'
import reports from './reports'

export default combineReducers({
  alert,
  modal,
  user,
  reports,
  form: formReducer,
})
