// @flow
import React from 'react'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'

import AuthRoute from '@src/components/react-router/AuthRoute'
import Sidebar from '@src/components/ui/Sidebar'
import OrdersLoader from '@src/components/orders/OrdersLoader'
import ReportsLoader from '@src/components/reports'
import BalanceLoader from '@src/components/balance/BalanceLoader'
import InvoicesLoader from '@src/components/invoices/InvoicesLoader'
import MyCompanyLoader from '@src/components/my-company'
import MyLocationLoader from '@src/components/my-location'
import SummaryContainer from '@src/components/summary/SummaryContainer'
import MasterListsLoader from '@src/components/master-lists/MasterListsLoader'
import MasterListLoader from '@src/components/master-list/MasterListLoader'
import MasterListEditLoader from '@src/components/master-list-edit/ProductListLoader'
import Order from '@src/components/order/OrderLoader'
import OfficeDetailLoader from '@src/components/office-detail/OfficeDetailLoader'
import AddBalance from '@src/components/add-balance'

import styles from './DashboardScreen.module.scss'

import type { ContextRouter } from 'react-router'

import { PERMISSION_CAN_MANAGE_COMPANY } from '@src/constants/permissions'

type DashboardScreenProps = {
  ...ContextRouter,
  currentUser: number,
}

export function DashboardScreen(props: DashboardScreenProps) {
  const { currentUser, currentOffice, currentCompany } = props

  const sideBarClassName = cx('col-2', styles.sidebar)
  const dashboardClassName = cx('col-10', styles.dashboard)
  const company = currentUser.companies[0]
  const homeServicesPeru = company.id === '1510'
  const isPeru = currentUser.region.id === '3'

  const companyEditable =
    currentUser &&
    currentUser.permissions.includes(PERMISSION_CAN_MANAGE_COMPANY)

  const SummaryRoute = () => (
    <SummaryContainer currentUser={currentUser} currentOffice={currentOffice} />
  )
  const OrdersRoute = props => {
    return <OrdersLoader currentOffice={currentOffice} {...props} />
  }

  const MasterListsRoute = () => (
    <MasterListsLoader
      currentUser={currentUser}
      currentOffice={currentOffice}
    />
  )
  const MasterListRoute = props => {
    return (
      <MasterListLoader
        currentUser={currentUser}
        currentOffice={currentOffice}
        currentCompany={currentCompany}
        {...props}
      />
    )
  }

  const OfficeDetailRoute = props => {
    return (
      <OfficeDetailLoader
        currentUser={currentUser}
        currentOffice={currentOffice}
        {...props}
      />
    )
  }

  const MyLocationRoute = props => {
    return <MyLocationLoader currentUser={currentUser} />
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className={sideBarClassName}>
          <Sidebar
            className={styles.dashboardNav}
            homeServicesPeru={homeServicesPeru}
            isPeru={isPeru}
            companyEditable={companyEditable}
          />

          <div className={styles.sidebarFooter}>
            <div className={styles.separator} />
            <p className="text-small-body mt-1 mb-0">info@gotandem.co</p>
          </div>
        </div>
        <div className={dashboardClassName}>
          <AuthRoute exact path="/" component={SummaryRoute} />
          <AuthRoute exact path="/orders" component={OrdersRoute} />
          <AuthRoute path="/reports" component={ReportsLoader} />
          <AuthRoute path="/balance" component={BalanceLoader} />
          <AuthRoute exact path="/master-lists" component={MasterListsRoute} />
          <AuthRoute
            exact
            path="/master-lists/:masterListId"
            component={MasterListRoute}
          />
          <AuthRoute
            exact
            path="/master-lists/:masterListId/edit"
            component={MasterListEditLoader}
          />

          <AuthRoute path="/orders/:orderId" component={Order} />

          <AuthRoute path="/offices/:officeId" component={OfficeDetailRoute} />

          <AuthRoute path="/add-balance" component={AddBalance} />

          <AuthRoute path="/invoices" component={InvoicesLoader} />

          <AuthRoute path="/my-company" component={MyCompanyLoader} />

          <AuthRoute path="/my-location" component={MyLocationRoute} />
        </div>
      </div>
    </div>
  )
}

export default withRouter(DashboardScreen)
