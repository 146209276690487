// @flow
import {
  createActionType as createActionTypeCreator,
  action as createAction,
  reducer as createReducer,
} from '@src/redux/utils'

const createActionType = createActionTypeCreator('Modal')

export const OPEN = createActionType('OPEN')
export const CLOSE = createActionType('CLOSE')

export const open = (data: any) => createAction(OPEN, data)
export const close = () => createAction(CLOSE)

const initialState = null

const reducer = createReducer(
  {
    [OPEN]: (state, action) => action.payload || {},
    [CLOSE]: () => null,
  },
  initialState
)

export default reducer
