// @flow
import Storage from 'local-storage-fallback'

import { BASE } from '@src/constants/storageKeys'

export default function clearStorage() {
  const ourKeys = Object.keys(Storage).filter(key => key.indexOf(BASE) === 0)
  ourKeys.forEach(key => Storage.removeItem(key))
}

export function removeKey(key: string) {
  Storage.removeItem(`${BASE}:${key}`)
}
