// @flow
const DEFAULT_REGION = {
  currency: 'MXN',
  id: '1',
  name: 'Mexico City, Mexico',
  slug: 'MEX',
  time_zone: 'America/Mexico_City',
}

export const getCurrentOfficeId = (state: *) =>
  state.user.preferences.currentOfficeId

export const getCurrentRegion = (state: *) => {
  if (state.user === undefined) {
    return DEFAULT_REGION
  }

  return state.user.region
}

export const isCurrentRegionInMexico = (state: *) =>
  /M[eé]xico$/.test(getCurrentRegion(state).name)

export const homeServicesPeru = (state: *) =>
  state.user.preferences.currentCompanyId === '1510'
