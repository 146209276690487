// @flow
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

export type ProductsOrder = {
  type: 'PRODUCT_ORDER' | 'SERVICE_ORDER',
  id: string,
  total_price: number,
}

export type Office = {
  balance: number,
}

export type Orders = Array<ProductsOrder>

export const query = gql`
  query Orders($officeId: ID!) {
    office(id: $officeId) {
      id
      balance
    }

    orders(office_id: $officeId, status: [CLOSED]) {
      type
      id
      total_price
      shipping_date
    }
  }
`

export default graphql(query, {
  options: ({ currentOfficeId }) => ({
    variables: {
      officeId: currentOfficeId,
    },
  }),
})
