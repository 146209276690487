// @flow
import React, { Component } from 'react'
import cx from 'classnames'

import styles from './Image.module.scss'

type ImageProps = {
  className?: string,
  responsive?: boolean,
  circle?: boolean,
  background?: boolean,
  src: string,
  alt?: string,
}

class Image extends Component<ImageProps, *> {
  render() {
    const {
      className,
      responsive,
      circle,
      background,
      src,
      alt = '',
      ...rest
    } = this.props

    const style = cx(
      {
        'img-fluid': responsive,
        'rounded-circle': circle,
      },
      className
    )

    return background ? (
      <div
        style={{
          backgroundImage: `url(${src})`,
        }}
        className={cx(styles.backgroundImage, className)}
        {...rest}
      />
    ) : (
      <img src={src} className={style} alt={alt} {...rest} />
    )
  }
}

export default Image
