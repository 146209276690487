// @flow
import React, { Component } from 'react'
import compose from 'lodash/flowRight'

import SummaryScreen from './SummaryScreen'

import redux from './redux'

import type { CurrentUser } from '@src/higherOrderComponents/withCurrentUser/graphql'

type SummaryContainerProps = {
  currency: string,
  currentUser: CurrentUser,
  currentOffice: {
    id: string,
    dashboard_promotion_message: string,
  },
}

export class SummaryContainer extends Component<SummaryContainerProps, *> {
  render() {
    const { currency, currentOffice, ...rest } = this.props

    return currentOffice ? (
      <SummaryScreen
        currency={currency}
        currentOffice={currentOffice}
        {...rest}
      />
    ) : null
  }
}

export default compose(redux)(SummaryContainer)
