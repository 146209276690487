// @flow
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'

export type OfficeInput = {|
  name?: string,
  employees?: number,
  phone?: string,
  additional_instructions?: string,
  address?: {
    street?: string,
    number?: string,
    state?: string,
    city?: string,
    postal_code?: string,
    latitude?: string,
    longitude?: string,
  },
  square_meters?: number,
  occupancy?: number,
  max_occupancy?: number,
|}

const withCreateNewOffice = graphql(
  gql`
    mutation CreateOffice($companyId: ID!, $officeInput: OfficeInput!) {
      create_office(company_id: $companyId, office: $officeInput) {
        id
        additional_instructions
        address {
          id
          street
          number
          state
          city
          postal_code
        }
        company {
          id
          image_url
        }
        created_at
        employees
        name
        phone
        square_meters
        occupancy
        max_occupancy
        updated_at
      }
    }
  `,
  {
    name: 'createNewOfficeMutation',
    props: ({ createNewOfficeMutation }) => ({
      createNewOffice: (companyId: string, officeInput: OfficeInput) =>
        createNewOfficeMutation({
          variables: { companyId, officeInput },
        }),
    }),
  }
)

export default compose(withCreateNewOffice)
