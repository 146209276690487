// @flow
/* eslint-disable react/style-prop-object */
import React from 'react'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'

import styles from './ChargeOrderModal.module.scss'
import CreditCardPaymentMethod, { CREDIT_CARD } from './CreditCardPaymentMethod'
import BankTransferPaymentMethod, {
  BANK_TRANSFER,
} from './BankTransferPaymentMethod'
import NewCreditCardPaymentMethod, {
  NEW_CREDIT_CARD,
} from './NewCreditCardPaymentMethod'
import Button from '@src/components/ui/Button'
import FormValidationError from '@src/components/ui/FormValidationError'
import FullScreenLoader from '@src/components/ui/FullScreenLoader'

import type { CreditCard, PaymentMethod, Office } from './types'

type ChargeOrderModalProps = {
  onSelectPaymentMethod: (paymentMethod: PaymentMethod) => void,
  selectedPaymentMethod?: PaymentMethod,
  creditCards: Array<CreditCard>,
  onClickPayButton: () => void,
  paying: boolean,
  error?: string,
  displayCreditCards: boolean,
  targetPrice: string,
  office: Office,
  orderId: string,
  isCurrentRegionInMexico: boolean,
}

export default function ChargeOrderModal(props: ChargeOrderModalProps) {
  const {
    onSelectPaymentMethod,
    selectedPaymentMethod,
    creditCards,
    onClickPayButton,
    paying,
    error,
    displayCreditCards,
    targetPrice,
    office,
    orderId,
    isCurrentRegionInMexico,
  } = props

  return (
    <div className={styles.container}>
      {paying && <FullScreenLoader />}

      <h1 className="text-title">
        <FormattedMessage
          id="ChargeOrderModal.title"
          defaultMessage="Paga para confirmar tu orden"
          description="Title"
        />
      </h1>

      <div className={styles.body}>
        <div className={styles.options}>
          <BankTransferPaymentMethod
            isCurrentRegionInMexico={isCurrentRegionInMexico}
            office={office}
            onSelect={onSelectPaymentMethod}
            orderId={orderId}
            selected={
              !!selectedPaymentMethod &&
              selectedPaymentMethod.type === BANK_TRANSFER
            }
          />
          {displayCreditCards &&
            creditCards.map(creditCard => (
              <CreditCardPaymentMethod
                key={creditCard.id}
                onSelect={onSelectPaymentMethod}
                selected={
                  !!selectedPaymentMethod &&
                  selectedPaymentMethod.type === CREDIT_CARD &&
                  !!selectedPaymentMethod.data &&
                  selectedPaymentMethod.data.id === creditCard.id
                }
                creditCard={creditCard}
              />
            ))}

          {displayCreditCards && (
            <NewCreditCardPaymentMethod
              onSelect={onSelectPaymentMethod}
              selected={
                !!selectedPaymentMethod &&
                selectedPaymentMethod.type === NEW_CREDIT_CARD
              }
              creditCardsCount={creditCards.length}
            />
          )}
        </div>

        {error && <FormValidationError error={error} />}

        <div className={styles.buttons}>
          <Button
            cta
            onClick={onClickPayButton}
            disabled={
              paying ||
              !selectedPaymentMethod ||
              selectedPaymentMethod.type === BANK_TRANSFER
            }
          >
            {paying ? (
              <FormattedMessage
                id="ChargeOrderModal.payButtonWhileDisabled"
                defaultMessage="PAGANDO..."
                description="Text in button to pay while disabled"
              />
            ) : (
              <FormattedMessage
                id="ChargeOrderModal.payButton"
                defaultMessage={`PAGAR ${targetPrice}`}
                description="Text in button to pay"
              />
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}

ChargeOrderModal.fragments = {
  creditCards: gql`
    fragment ChargeOrderModalCreditCards on CreditCard {
      ...CreditCardPaymentMethod
    }
    ${CreditCardPaymentMethod.fragments.creditCard}
  `,
}
