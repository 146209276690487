// @flow
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

export type Address = {
  id: string,
  street: string,
  colony: string,
  city: string,
  number: string,
  postal_code: string,
  state: string,
}

export type ProductsOrder = {
  type: 'PRODUCT_ORDER' | 'SERVICE_ORDER',
  id: string,
  shipping_date: string,
  address: Address,
}

export type Orders = Array<ProductsOrder>

export const query = gql`
  query Orders($officeId: ID!) {
    orders(
      office_id: $officeId
      status: [PENDING_ACCEPTANCE, ACCEPTED, PARTIALLY_DELIVERED]
    ) {
      type
      id
      shipping_date
      address {
        id
        street
        colony
        city
        number
        postal_code
        state
      }
    }
  }
`

export default graphql(query, {
  options: ({ currentOfficeId }) => ({
    variables: {
      officeId: currentOfficeId,
    },
  }),
})
