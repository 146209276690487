// @flow
import invariant from 'invariant'

import ConektaTokenGenerator from './ConektaTokenGenerator'
import StripeTokenGenerator from './StripeTokenGenerator'

import type { CreditCard, TokenGenerator } from './types'

export default class PaymentTokensGenerator {
  creditCard: CreditCard

  constructor(creditCard: CreditCard) {
    this.creditCard = creditCard
  }

  async generateTokens() {
    const [conekta, stripe] = await Promise.all([
      this._generateConektaToken(),
      this._generateStripeToken(),
    ])

    return { conekta, stripe }
  }

  _generateConektaToken() {
    invariant(
      process.env.REACT_APP_CONEKTA_PUBLISHABLE_KEY,
      "The public key for Conekta's API is missing. You can get one at https://admin.conekta.com/ within the API Keys section."
    )

    const tokenGenerator: TokenGenerator = new ConektaTokenGenerator(
      process.env.REACT_APP_CONEKTA_PUBLISHABLE_KEY
    )
    return tokenGenerator.generateToken(Object.assign({}, this.creditCard))
  }

  _generateStripeToken() {
    invariant(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
      "The public key for Stripe's API is missing. You can get one at https://dashboard.stripe.com/account/apikeys."
    )

    const tokenGenerator: TokenGenerator = new StripeTokenGenerator(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    )
    return tokenGenerator.generateToken(Object.assign({}, this.creditCard))
  }
}
