// @flow
import React from 'react'

import InputFormGroupField from '@src/components/ui/Input'
import { validCreditCardNumber } from './validations'

type CreditCardNumberInputProps = {
  name: string,
  validate?: Function | Array<Function>,
}

export default function CreditCardNumberInputFormGroupField(
  props: CreditCardNumberInputProps
) {
  let validations = [validCreditCardNumber]

  if (props.validate) {
    validations = validations.concat(props.validate)
  }

  return (
    <InputFormGroupField
      label="Número de tarjeta"
      placeholder="XXXX XXXX XXXX XXXX"
      {...props}
      validate={validations}
    />
  )
}
