// @flow
import { Component } from 'react'
import { connect } from 'react-redux'

import {
  display as displayAlert,
  remove as removeAlert,
} from '@src/redux/modules/alert'

import type { AlertInput, Alert as AlertType } from '@src/redux/modules/alert'

type AlertProps = {
  displayAlert: (alertInput: AlertInput) => { payload: AlertType },
  removeAlert?: (id: string) => void,
  type: *,
  onClick?: () => void,
  children: any,
}

export class Alert extends Component<AlertProps, *> {
  alertId: any

  componentDidMount() {
    const { displayAlert, type, children, onClick } = this.props
    const displayAlertAction = displayAlert({ type, children, onClick })
    this.alertId = displayAlertAction.payload.id
  }

  componentWillUnmount() {
    this.props.removeAlert && this.props.removeAlert(this.alertId)
  }

  render() {
    return null
  }
}

const actions = {
  displayAlert,
  removeAlert,
}

export default connect(
  null,
  actions
)(Alert)
