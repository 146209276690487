// @flow
import React from 'react'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'

import styles from './RequestSuccessModal.module.scss'
import Button from '@src/components/ui/Button'

type RequestSuccessModalProps = {
  closeModal: () => void,
}

export default function RequestSuccessModal(props: RequestSuccessModalProps) {
  return (
    <div className={styles.container}>
      <h1 className={cx('text-title', styles.title)}>
        <FormattedMessage
          id="RequestSuccessModal.title"
          defaultMessage="¡Listo!"
          description="Title of the modal"
        />
      </h1>

      <p className="text-dark">
        <FormattedMessage
          id="RequestSuccessModal.description"
          defaultMessage="Evaluaremos tu solicitud y te enviaremos un email en caso de que aprobemos tu solicitud."
          description="Description of the modal"
        />
      </p>

      <Button className={styles.closeButton} onClick={props.closeModal}>
        <FormattedMessage
          id="RequestSuccessModal.closeButton"
          defaultMessage="OK"
          description="Close modal button text"
        />
      </Button>
    </div>
  )
}
