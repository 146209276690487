// @flow
// Forked from https://github.com/airbnb/react-dates/blob/master/src/components/OutsideClickHandler.jsx
import React, { Component } from 'react'

import type { Node as ReactNode } from 'react'

type OutsideClickHandlerProps = {
  className?: string,
  children: ReactNode,
  onOutsideClick: (e: SyntheticEvent<*>) => void,
}

export default class OutsideClickHandler extends Component<
  OutsideClickHandlerProps,
  *
> {
  onOutsideClick: Function
  setChildNodeRef: Function
  childNode: *

  constructor(props: OutsideClickHandlerProps) {
    super(props)

    this.onOutsideClick = this.onOutsideClick.bind(this)
    this.setChildNodeRef = this.setChildNodeRef.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.onOutsideClick, { capture: true })
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onOutsideClick, {
      capture: true,
    })
  }

  onOutsideClick(e: SyntheticEvent<*>) {
    const { onOutsideClick } = this.props
    const { childNode } = this
    const isDescendantOfRoot = childNode && childNode.contains(e.target)

    if (!isDescendantOfRoot) {
      onOutsideClick(e)
    }
  }

  setChildNodeRef(ref: *) {
    this.childNode = ref
  }

  render() {
    return (
      <div className={this.props.className} ref={this.setChildNodeRef}>
        {this.props.children}
      </div>
    )
  }
}
