// @flow
import React from 'react'

import FormGroup from './FormGroup'

import type { ComponentType } from 'react'

export default function createFormGroup(WrappedComponent: ComponentType<*>): * {
  function ComponentFormGroup(props: any) {
    return <FormGroup {...props} formControl={WrappedComponent} />
  }

  const wrappedComponentName =
    (WrappedComponent instanceof React.Component &&
      WrappedComponent.displayName) ||
    WrappedComponent.name ||
    'Component'

  ComponentFormGroup.displayName = `FormGroup(${wrappedComponentName})`

  return ComponentFormGroup
}
