// @flow
import moment from 'moment-timezone'

import {
  createActionType as createActionTypeCreator,
  action as createAction,
  reducer as createReducer,
} from '@src/redux/utils'

const createActionType = createActionTypeCreator('Reports')

export const SET_DATE_RANGE = createActionType('SET_DATE_RANGE')
export const RESET_DATE_RANGE = createActionType('RESET_DATE_RANGE')

export const setDateRange = (startingDate: string, endingDate: string) =>
  createAction(SET_DATE_RANGE, {
    startingDate,
    endingDate,
  })
export const resetDateRange = () => createAction(RESET_DATE_RANGE)

const initialState = {
  startingDate: moment()
    .subtract(7, 'days')
    .startOf('day')
    .toISOString(),
  endingDate: moment().toISOString(),
}

const reducer = createReducer(
  {
    [SET_DATE_RANGE]: (state, action) => ({
      ...state,
      startingDate: action.payload.startingDate,
      endingDate: action.payload.endingDate,
    }),
    [RESET_DATE_RANGE]: state => ({
      ...state,
      startingDate: initialState.startingDate,
      endingDate: initialState.endingDate,
    }),
  },
  initialState
)

export default reducer
