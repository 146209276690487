// @flow
import React from 'react'
import ReactDOM from 'react-dom'

import './styles/main.scss'
import App from './App'
import AppErrorBoundary from './AppErrorBoundary'

function render(Component) {
  ReactDOM.render(
    <AppErrorBoundary>
      <Component />
    </AppErrorBoundary>,
    // $FlowIgnore
    document.getElementById('app')
  )
}

render(App)

// $FlowIgnore
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App.js', () => {
    const NextApp = require('./App').default
    render(NextApp)
  })
}
