// @flow
import { connect } from 'react-redux'

import {
  setCurrentCompanyId,
  setCurrentOfficeId,
  setCurrentRegionId,
} from '@src/redux/modules/user/preferences'
import { setRegion } from '@src/redux/modules/user/region'

const select = state => ({
  userIsLoggedIn: state.user.isLoggedIn,
  userPreferences: state.user.preferences,
})

const actions = {
  setCurrentCompanyId,
  setCurrentOfficeId,
  setCurrentRegionId,
  setCurrentRegion: setRegion,
}

export default connect(
  select,
  actions
)
