import React, { Component } from 'react'

import cx from 'classnames'
import styles from './Tab.module.scss'

class Tab extends Component {
  onClick = () => {
    const { label, onClick } = this.props
    onClick(label)
  }

  render() {
    const {
      onClick,
      props: { activeTab, label },
    } = this

    let active = ''

    if (activeTab === label) {
      active = styles.navLinkActive
    }

    return (
      <li className={'list-inline-item'} onClick={onClick}>
        <span className={cx(styles.navLink, active)}>{label}</span>
      </li>
    )
  }
}

export default Tab
