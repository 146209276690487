// @flow
import type {
  Action,
  ActionType,
  ActionPayload,
  State,
  Reducer,
} from '@src/redux/types'

const REACT_APP_NAME: string = process.env.REACT_APP_NAME || 'tandem'

type ActionTypeCreator = (actionTypeName: string) => ActionType
export function createActionType(baseName: string): ActionTypeCreator {
  return actionTypeName => `${REACT_APP_NAME}/${baseName}/${actionTypeName}`
}

export function action(type: ActionType, payload?: ActionPayload): Action {
  const action: Action = { type }

  if (payload) {
    action.payload = payload
  }

  if (payload instanceof Error) {
    action.error = true
  }

  return action
}

type ActionTypeHandlers = { [actionType: string]: Reducer }
export function reducer(
  typeHandlers: ActionTypeHandlers,
  initialState: State
): Reducer {
  return (state: State = initialState, action: Action) => {
    const typeHandler = typeHandlers[action.type]

    if (typeof typeHandler === 'function') {
      const nextState = typeHandlers[action.type](state, action)
      return nextState
    }

    return state
  }
}
