// @flow
import React from 'react'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'

import styles from './PaymentMethodsEmpty.module.scss'
import Card from '@src/components/ui/Card'
import Image from '@src/components/ui/Image'
import emptyCard from './images/empty-card.svg'

export default function PaymentMethodsEmpty() {
  return (
    <Card className={styles.container}>
      <Image background className={styles.icon} src={emptyCard} />
      <p className={cx('text-headline', styles.description)}>
        <FormattedMessage
          id="PaymentMethodsEmpty.description"
          defaultMessage="Áun no tienes ningún método de pago. Haz click en agregar método de pago para añadir uno."
          description="Text displayed when the user still has no payment methods."
        />
      </p>
    </Card>
  )
}
