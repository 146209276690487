// @flow
import React from 'react'
import cx from 'classnames'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import Card from '@src/components/ui/Card'
import Icon from '@src/components/ui/Icon'
import WidgetLoader from '@src/components/ui/WidgetLoader'
import buildAddressString from '@src/utils/buildAddressString'

import EmptyScreen from './EmptyScreen'

import styles from '../SummaryScreen.module.scss'

import type { Orders } from './graphql'

type NextEventsWidgetScreenProps = {
  orders?: Orders,
  loading: boolean,
}

const renderWithData = orders => {
  return (
    <Card className={styles.summaryCard}>
      <h3 className="text-small text-gray text-uppercase">
        <FormattedMessage
          id="NextEventsWidget.title"
          defaultMessage="Próximos eventos"
          description="Next events title"
        />
      </h3>
      <div className={styles.summaryCardBody}>
        {orders.map(
          order =>
            order.type === 'PRODUCT_ORDER' && (
              <div className={styles.nextEvent} key={order.id}>
                <p>
                  <NavLink
                    to={`/orders/${order.id}`}
                    className="text-body-2 link-blue"
                  >
                    <FormattedMessage
                      id="NextEventsWidget.nextEventCategory"
                      defaultMessage="Entrega de órden de productos"
                      description="Next events event category"
                    />
                  </NavLink>
                </p>
                <div className="d-flex">
                  <span
                    className={cx('text-small text-gray', styles.nextEventInfo)}
                  >
                    <Icon className={cx('icon', styles.icon)} name="calendar" />

                    <FormattedMessage
                      id="Summary.nextEventDate"
                      defaultMessage={moment(order.shipping_date).format(
                        'D [de] MMMM'
                      )}
                      description="Date of the next planned event."
                    />
                  </span>
                  <span
                    className={cx('text-small text-gray', styles.nextEventInfo)}
                  >
                    <Icon className={cx('icon', styles.icon)} name="location" />

                    <FormattedMessage
                      id="Summary.nextEventLocation"
                      defaultMessage={buildAddressString(order.address)}
                      description="Location of the next planned event."
                    />
                  </span>
                </div>
              </div>
            )
        )}
      </div>
    </Card>
  )
}

const renderNextEventsWidget = orders => {
  return (
    <div>{orders.length > 0 ? renderWithData(orders) : <EmptyScreen />}</div>
  )
}

function NextEventsWidgetScreen(props: NextEventsWidgetScreenProps) {
  const { orders = [], loading } = props

  return (
    <div className="col-6">
      {loading ? <WidgetLoader /> : renderNextEventsWidget(orders)}
    </div>
  )
}

export default NextEventsWidgetScreen
