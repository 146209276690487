// @flow
import validator from 'validator'
import moment from 'moment-timezone'
import convertGeocoderResultToAddress from '@src/utils/convertGeocoderResultToAddress'
import * as errorReporter from '@src/lib/errorReporter'

type FieldValue = any

export const isEmpty = (value: FieldValue) => {
  try {
    return value === undefined || value === null || value.trim() === ''
  } catch (e) {
    return false
  }
}

export function isRequired(value: FieldValue) {
  const invalid = isEmpty(value)
  if (invalid) {
    return 'Campo obligatorio'
  }
}

export function isEmail(value: FieldValue) {
  if (isEmpty(value)) {
    return isRequired(value)
  }

  if (!validator.isEmail(value)) {
    return 'Debe ingresar un a dirección válida'
  }
}

export function isValidPassword(value: FieldValue) {
  if (isEmpty(value)) {
    return isRequired(value)
  }

  if (value.length < 8) {
    return 'Tu contraseña debe tener al menos 8 caracteres'
  }
}

export function regionalPhoneLength(
  value: FieldValue,
  allValues: any,
  region_slug?: string
) {
  if (!value || !allValues) {
    return false
  }

  const region = region_slug || allValues['region_slug']
  const count = value.length
  var are_expected_digits = false
  var errorMessage = ''

  if (region === 'MEX') {
    are_expected_digits = count === 10
    errorMessage = `El teléfono debe tener 10 digitos`
  } else if (region === 'LIM') {
    are_expected_digits = count === 9 || count === 7
    errorMessage = `El teléfono debe tener 9 dígitos para celular o 7 para fijo`
  }

  return are_expected_digits ? undefined : errorMessage
}

export function greaterOrEqualThan(min: number) {
  return (value: FieldValue) => {
    if (isEmpty(value)) {
      return isRequired(value)
    }

    if (!validator.isFloat(value, { min })) {
      return `El valor debe ser mayor o igual a ${min}`
    }
  }
}

export function greaterOrEqualThanDate(minDate: number | string | Date) {
  return (value: FieldValue) => {
    if (isEmpty(value)) {
      return isRequired(value)
    }

    if (!moment(value).isSameOrAfter(minDate)) {
      const formattedMinDate = moment(minDate).format('LL')
      return `La fecha debe ser igual o despues a ${formattedMinDate}`
    }
  }
}

export function isValidAddress(value: FieldValue) {
  const errorMessage = 'Intenta escribiendo tu dirección completa por favor'
  const mandatoryFields = ['street', 'number', 'state', 'latitude', 'longitude']

  try {
    const address = value.gmaps
      ? convertGeocoderResultToAddress(value.gmaps)
      : value
    const isInvalid = mandatoryFields.some(key => !address[key])
    return isInvalid ? errorMessage : undefined
  } catch (e) {
    errorReporter.log(e)
    return errorMessage
  }
}

export function isEqualTo(fieldName: string, fieldLabel: string) {
  return (value: FieldValue, allValues: any) => {
    const errorMessage = `Este campo debe ser igual al campo ${fieldLabel}`

    return value !== allValues[fieldName] ? errorMessage : false
  }
}
