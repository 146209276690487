// @flow
export const separator = ' / '

function normalize(value?: string) {
  if (!value) {
    return value
  }

  let normalizedValue = ''

  const month = autoCompleteMonth(value.slice(0, 2))
  normalizedValue = stripNonDigits(month)

  const monthIsComplete = normalizedValue.length === 2
  if (monthIsComplete) {
    normalizedValue = normalizedValue + separator
  }

  if (value.includes(separator)) {
    const yearIndex = value.indexOf(separator) + separator.length
    let year = value.substring(yearIndex)

    year = stripNonDigits(year)
    year = year.slice(0, 2)

    return normalizedValue + year
  }

  if (partiallyIncludes(value, separator)) {
    return normalizedValue.slice(0, 2)
  }

  return normalizedValue
}

function autoCompleteMonth(month: string) {
  if (parseInt(month[0], 10) > 1) {
    month = `0${month}`
  }

  return month.slice(0, 2)
}

function stripNonDigits(s: string) {
  return s.replace(/\D/g, '')
}

function partiallyIncludes(value: string, searchString: string) {
  for (let i = 0; i < searchString.length; i++) {
    if (value.includes(searchString[i])) {
      return true
    }
  }

  return false
}

export default normalize
