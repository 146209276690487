// @flow
import * as authToken from '@src/utils/authToken'
import clearStorage from '@src/utils/clearStorage'

export function isLoggedIn(): boolean {
  return !!authToken.get()
}

export function logOut(): void {
  authToken.remove()
  clearStorage()
}
