// @flow

import React from 'react'
import cx from 'classnames'

import Icon from '@src/components/ui/Icon'
import styles from './Alert.module.scss'

type AlertProps = {
  children: any,
  type?: string,
  onClick?: () => void,
}

function getAlertIconName(type?: string) {
  switch (type) {
    case 'success':
      return 'delivered'
    case 'danger':
      return 'unavailable'
    default:
      return 'pending'
  }
}

function Alert(props: AlertProps) {
  const { children, type, onClick } = props

  const wrapperClassName = cx(
    styles.wrapper,
    onClick && 'clickable',
    type === 'success' && styles.success,
    type === 'danger' && styles.danger,
    type === 'info' && styles.info,
    type === 'warning' && styles.warning
  )

  const iconName = getAlertIconName(type)

  return (
    <div className={wrapperClassName} onClick={onClick}>
      <div className={cx('container', styles.container)}>
        <Icon name={iconName} className={styles.icon} />

        <div className={styles.content}>{children}</div>
      </div>
    </div>
  )
}

export default Alert
