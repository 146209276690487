// @flow
export const RECHARGE_METHODS: {
  CREDIT_CARD: 'CREDIT_CARD',
  BANK_TRANSFER: 'BANK_TRANSFER',
  PROMOTIONAL_CODE: 'PROMOTIONAL_CODE',
  CREDIT_CARD_INSTRUCTIONS: 'CREDIT_CARD_INSTRUCTIONS',
  YAPE: 'YAPE',
} = {
  CREDIT_CARD: 'CREDIT_CARD',
  BANK_TRANSFER: 'BANK_TRANSFER',
  PROMOTIONAL_CODE: 'PROMOTIONAL_CODE',
  CREDIT_CARD_INSTRUCTIONS: 'CREDIT_CARD_INSTRUCTIONS',
  YAPE: 'YAPE',
}
export const MAXIMUM_DIFFERENCE_TO_PAY_ORDER = -100
