// @flow
/* eslint-disable react/style-prop-object */
import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import styles from './NotEnoughBalanceModal.module.scss'

type NotEnoughBalanceModalProps = {
  targetPrice: string,
  currency: string,
  officeBalance: number,
}

export default function NotEnoughBalanceModal(
  props: NotEnoughBalanceModalProps
) {
  const { targetPrice, officeBalance, currency } = props

  return (
    <div className={styles.container}>
      <h1 className="text-title">
        <FormattedMessage
          id="NotEnoughBalanceModal.title"
          defaultMessage="Presupuesto insuficiente"
          description="Title"
        />
      </h1>

      <p className="d-flex justify-content-between">
        <FormattedMessage
          id="NotEnoughBalanceModal.explanation"
          description="Not enough balance explanation text"
          defaultMessage="El presupuesto restante es insuficiente para hacer esta orden. Contacta info@gotandem.co para aumentar el presupuesto o quita algunos productos de la orden."
          className="text-small"
        />
      </p>

      <div className={styles.body}>
        <div>
          <p className="d-flex justify-content-between text-gray text-uppercase">
            <FormattedMessage
              id="NotEnoughBalanceModal.orderTotalTitle"
              defaultMessage="Total"
              description="Order total title"
              className="text-small"
            />
            <FormattedNumber
              value={parseFloat(targetPrice) / 100}
              currency={currency}
              style="currency"
              currencyDisplay="symbol"
            />
          </p>
          <p className="d-flex justify-content-between text-gray text-uppercase">
            <FormattedMessage
              id="NotEnoughBalanceModal.officeBalanceTitle"
              defaultMessage="Saldo disponible"
              description="Order total title"
              className="text-small"
            />
            <FormattedNumber
              value={officeBalance / 100}
              currency={currency}
              style="currency"
              currencyDisplay="symbol"
            />
          </p>
        </div>
      </div>
    </div>
  )
}
