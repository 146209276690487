import React, { Component } from 'react'

import Tab from './Tab'
import styles from './Tabs.module.scss'

class Tabs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: this.props.children[0].props.label,
    }
  }

  onClickTabItem = tab => {
    this.setState({ activeTab: tab })
  }

  render() {
    const {
      props,
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this

    return (
      <div className={props.className}>
        <ul className={styles.navBar}>
          {children.map(child => {
            const { label } = child.props

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            )
          })}
        </ul>
        <div>
          {children.map(child => {
            if (child.props.label !== activeTab) return undefined
            return child.props.children
          })}
        </div>
      </div>
    )
  }
}

export default Tabs
