// @flow
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import compose from 'lodash/flowRight'

import { UpdateMasterListModal } from './UpdateMasterListModal'
import type { MasterListInput } from './types'

const updateMasterListMutation = graphql(
  gql`
    mutation UpdateMasterList($id: ID!, $masterList: MasterListInput!) {
      update_master_list(id: $id, master_list: $masterList) {
        ...UpdateMasterListModalMasterList
      }
    }
    ${UpdateMasterListModal.fragments.masterList}
  `,
  {
    name: 'updateMasterListMutation',
    props: ({ updateMasterListMutation }) => ({
      updateMasterList: (id: string, masterListInput: MasterListInput) =>
        updateMasterListMutation({
          variables: {
            id,
            masterList: masterListInput,
          },
        }),
    }),
  }
)

export default compose(updateMasterListMutation)
