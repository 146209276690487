// @flow
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'

export type AddressInput = {
  street: ?string,
  number: ?string,
  state: ?string,
  city: ?string,
  postal_code: ?string,
  latitude: ?number,
  longitude: ?number,
}

export type Company = {
  id: string,
}

export type CompanyInput = {
  name?: string,
  description?: string,
  phone?: string,
}

export type OfficeInput = {
  name: string,
  address: AddressInput,
  phone: string,
  square_meters?: number,
  occupancy?: number,
  max_occupancy?: number,
}

export type Office = {
  id: string,
}

const createCompanyMutation = graphql(
  gql`
    mutation createCompany($company: CompanyInput!) {
      create_company(company: $company) {
        id
      }
    }
  `,
  {
    name: 'createCompanyMutation',
    props: ({ createCompanyMutation }) => ({
      createCompany: (company: CompanyInput) =>
        createCompanyMutation({
          variables: { company },
        }),
    }),
  }
)

const updateCompanyMutation = graphql(
  gql`
    mutation updateCompany($companyId: ID!, $company: CompanyInput!) {
      update_company(id: $companyId, company: $company) {
        id
        name
        description
        phone
      }
    }
  `,
  {
    name: 'updateCompanyMutation',
    props: ({ updateCompanyMutation }) => ({
      updateCompany: (companyId: String, company: CompanyInput) =>
        updateCompanyMutation({
          variables: { companyId, company },
        }),
    }),
  }
)

const createOfficeMutation = graphql(
  gql`
    mutation createOffice($companyId: ID!, $office: OfficeInput!) {
      create_office(company_id: $companyId, office: $office) {
        id
        name
        phone
        square_meters
        occupancy
        max_occupancy
      }
    }
  `,
  {
    name: 'createOfficeMutation',
    props: ({ createOfficeMutation }) => ({
      createOffice: (companyId: string, office: OfficeInput) =>
        createOfficeMutation({
          variables: { companyId, office },
        }),
    }),
  }
)

export default compose(
  createCompanyMutation,
  updateCompanyMutation,
  createOfficeMutation
)
