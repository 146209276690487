// @flow
import React, { Component } from 'react'
import cx from 'classnames'

import type { ComponentType } from 'react'

type HTMLFormComponent = 'input' | 'textarea'

export type FormGroupProps = {
  label?: string,
  helpText?: string,
  error?: string,
  meta: {
    error?: string,
    touched?: boolean,
  },
  className?: string,
  containerClassName?: string,
  formControl: ComponentType<*> | HTMLFormComponent,
}

export default class FormGroup extends Component<FormGroupProps, *> {
  render() {
    const {
      label,
      helpText,
      meta,
      formControl,
      containerClassName,
      className,
      ...formControlAttributes
    } = this.props

    const FormControl = formControl

    // $FlowIgnore
    const formControlId = formControlAttributes.id || formControlAttributes.name
    const helpTextId = helpText && `${formControlId}HelpText`

    let error
    if (meta.touched) {
      error = meta.error
    }

    const formControlClassName = cx('form-control', className, {
      'is-invalid': !!error,
    })

    return (
      <div className={cx('form-group', containerClassName)}>
        {label && <label htmlFor={formControlId}>{label}</label>}

        <FormControl
          id={formControlId}
          className={formControlClassName}
          aria-describedby={helpTextId}
          meta={meta}
          {...formControlAttributes}
        />

        {error && <div className="form-text invalid-feedback">{error}</div>}

        {helpText && (
          <small id={helpTextId} className="form-text">
            {helpText}
          </small>
        )}
      </div>
    )
  }
}
