// @flow
import React, { Component } from 'react'
import cx from 'classnames'

import styles from './Card.module.scss'

type CardProps = {
  clickable?: boolean,
  className?: string,
  children?: any,
  onClick?: Function,
}

export default class Card extends Component<CardProps, *> {
  render() {
    const className = cx(
      styles.container,
      this.props.clickable && styles.clickable,
      this.props.className
    )

    return (
      <div className={className} onClick={this.props.onClick}>
        {this.props.children}
      </div>
    )
  }
}
