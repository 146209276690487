// @flow
import { connect } from 'react-redux'

import { remove as removeAlert } from '@src/redux/modules/alert'

const select = state => ({
  alerts: state.alert,
})

const actions = {
  removeAlert,
}

export default connect(
  select,
  actions
)
