// @flow
import React from 'react'
import cx from 'classnames'
import { FormattedMessage, defineMessages } from 'react-intl'

import styles from './OfficeInformationModal.module.scss'
import Button from '@src/components/ui/Button'
import Input from '@src/components/ui/Input'
import GeoSearchControl from '@src/components/ui/GeoSearchControl'
import FormValidationError from '@src/components/ui/FormValidationError'
import {
  isRequired,
  isValidAddress,
  greaterOrEqualThan,
  regionalPhoneLength,
} from '@src/utils/validation'

import type { MessageDescriptor } from 'react-intl'

const validateOccupancyData = value =>
  value ? greaterOrEqualThan(1)(value) : undefined

const messages = defineMessages({
  companyNameLabel: {
    id: 'OfficeInformationModal.companyNameLabel',
    defaultMessage: 'Nombre de tu organización',
    description:
      "Label for the form field asking the name of the user' company",
  },
  officeNameLabel: {
    id: 'OfficeInformationModal.officeNameLabel',
    defaultMessage: 'Nombre de la ubicación',
    description: "Label for the form field asking the name of the user' office",
  },
  officeNamePlaceholder: {
    id: 'OfficeInformationModal.officeNamePlaceholder',
    defaultMessage: 'Ej. Sede Polanco',
    description:
      "Placeholder text for the form field asking the name of the user' office",
  },
  addressLabel: {
    id: 'OfficeInformationModal.addressLabel',
    defaultMessage: 'Escribe la dirección completa de tu ubicación',
    description:
      "Label for the form field asking the location of the user's office",
  },
  phoneLabel: {
    id: 'OfficeInformationModal.phoneLabel',
    defaultMessage: 'Teléfono de contacto para entregas',
    description: "Label for the form field asking the user's phone number",
  },
  occupancyLabel: {
    id: 'OfficeInformationModal.occupancyLabel',
    defaultMessage: 'Número de personas que usualmente trabajan ahí',
    description: 'Label for the form field asking the occupancy in the office',
  },
  squareMetersLabel: {
    id: 'OfficeInformationModal.squareMetersLabel',
    defaultMessage: 'Metros cuadrados usables (opcional)',
    description:
      'Label for the form field asking the office area in square meters',
  },
  maxOccupancyLabel: {
    id: 'OfficeInformationModal.maxOccupancyLabel',
    defaultMessage: 'Capacidad máxima de ocupación (opcional)',
    description:
      'Label for the form field asking the max occupancy in the office',
  },
})

type OfficeInformationModalProps = {
  handleSubmit: () => void,
  submitting: boolean,
  error?: any,
  companyId?: boolean,
  regionSlug: string,
  intl: {
    formatMessage: (
      messageDescriptor: MessageDescriptor,
      values?: Object
    ) => string,
  },
}

export default function OfficeInformationModal(
  props: OfficeInformationModalProps
) {
  const {
    intl: { formatMessage },
    companyId,
  } = props

  const needsCompanyInformation = !companyId

  const regionPhoneLengthValidation = (value: any, allValues: any) => {
    return regionalPhoneLength(value, allValues, props.regionSlug)
  }

  return (
    <div className={styles.container}>
      <div className={cx(styles.column, styles.body)}>
        <h4>
          <FormattedMessage
            id="OfficeInformationModal.title"
            defaultMessage="Querémos saber más sobre tu ubicación"
            description={`The office information modal form's title`}
          />
        </h4>

        <p className="text-secondary">
          <FormattedMessage
            id="OfficeInformationModal.description"
            defaultMessage="Detalles como el nombre de la ubicación y la dirección son importantes para nosotros para asegurarnos brindarte reportes precisos."
            description={`The office information modal form's description`}
          />
        </p>
      </div>

      <form
        className={cx(styles.column, styles.form)}
        onSubmit={props.handleSubmit}
      >
        {needsCompanyInformation && (
          <Input
            name="companyName"
            label={formatMessage(messages.companyNameLabel)}
            validate={[isRequired]}
          />
        )}

        <Input
          name="name"
          label={formatMessage(messages.officeNameLabel)}
          placeholder={formatMessage(messages.officeNamePlaceholder)}
          validate={[isRequired]}
        />

        <GeoSearchControl
          name="address"
          placeholder=" "
          label={formatMessage(messages.addressLabel)}
          validate={[isRequired, isValidAddress]}
        />

        <Input
          name="phone"
          label={formatMessage(messages.phoneLabel)}
          validate={[isRequired, regionPhoneLengthValidation]}
        />

        <Input
          type="number"
          name="occupancy"
          label={formatMessage(messages.occupancyLabel)}
          validate={[isRequired, validateOccupancyData]}
        />

        <Input
          type="number"
          name="squareMeters"
          label={formatMessage(messages.squareMetersLabel)}
          validate={[validateOccupancyData]}
        />

        <Input
          type="number"
          name="maxOccupancy"
          label={formatMessage(messages.maxOccupancyLabel)}
          validate={[validateOccupancyData]}
        />
        <Input type="string" hidden={true} name="regionSlug" />

        {props.error && <FormValidationError error={props.error} />}

        <Button
          className={styles.submitButton}
          type="submit"
          cta
          disabled={props.submitting}
        >
          {props.submitting ? (
            <FormattedMessage
              id="OfficeInformationModal.submitButtonWhileSubmitting"
              defaultMessage="Enviando información..."
              description="Submit button text while the form is being submitted"
            />
          ) : (
            <FormattedMessage
              id="OfficeInformationModal.submitButton"
              defaultMessage="Continuar"
              description="Submit button text"
            />
          )}
        </Button>
      </form>
    </div>
  )
}
