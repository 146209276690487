// @flow
import React, { Component } from 'react'
import cx from 'classnames'

import Icon from '@src/components/ui/Icon'

import styles from './CopyableInput.module.scss'

type CopyableInputContainerProps = {
  value: string,
  className: string,
}

type CopyableInputContainerState = {
  copying: boolean,
  copied: boolean,
}

class CopyableInput extends Component<
  CopyableInputContainerProps,
  CopyableInputContainerState
> {
  onCopy: () => void
  onFocus: () => void
  linkInput: ?HTMLInputElement

  constructor(props: CopyableInputContainerProps) {
    super(props)

    this.state = {
      copying: false,
      copied: false,
    }

    this.onCopy = this.onCopy.bind(this)
    this.onFocus = this.onFocus.bind(this)
  }

  onCopy() {
    this.onFocus()

    this.setState({ copying: true })

    try {
      const success = document.execCommand('copy')
      this.setState({ copying: false, copied: success })
    } catch (err) {
      this.setState({ copying: false, copied: false })
    }
  }

  onFocus() {
    if (!this.linkInput) {
      return null
    }

    this.linkInput.select()
  }

  render() {
    return (
      <div>
        <input
          type="text"
          value={this.props.value}
          title={this.props.value}
          readOnly
          className={cx('border-0', this.props.className)}
          ref={el => (this.linkInput = el)}
          onClick={this.onFocus}
        />
        <button
          type="button"
          className={cx('border-0', styles.copyableButton)}
          onClick={this.onCopy}
        >
          <Icon name="copy" />
        </button>
      </div>
    )
  }
}

export default CopyableInput
