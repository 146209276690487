// @flow
import React, { Component } from 'react'

import redux from './redux'
import Alert from './AlertRootAlert'

import type { Alert as AlertType } from '@src/redux/modules/alert'

type AlertRootProps = {
  alerts: Array<AlertType>,
  remove: (data: any) => void,
}

class AlertRoot extends Component<AlertRootProps, *> {
  handleClose(index: number) {
    this.props.remove({ index })
  }

  render() {
    const { alerts } = this.props

    return (
      <div>
        {alerts.map(alert => {
          const { id, type, children, onClick } = alert

          return (
            <Alert key={id} type={type} onClick={onClick}>
              {children}
            </Alert>
          )
        })}
      </div>
    )
  }
}

export default redux(AlertRoot)
